import { Types } from '@anyvm/moveup-sdk';
import { AccordionSummary, CircularProgress } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Link, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { AddressModules } from '../component/AddressModules';
import { AppAccordion, AppAccordionDetails } from '../component/AppAccordion';
import { BackButton } from '../component/BackButton';
import { CopyIcon } from '../component/CopyIcon';
import { NoDataFound } from '../component/NoDataFound';
import { PaginationRounded } from '../component/PaginationRounded';
import { AppTab, AppTabs, TabPanel } from '../component/TabPanel';
import { TransactionsItem, TransactionsTitle } from '../component/Transactions';
import AddressContextProvider, { useAddressContext } from '../context/AddressContext';
import { useGetAccountMoveUpBalance } from '../hooks/useGetAccountMoveupBalance';
import { CoinBalances } from '../model/CoinBalances';
import { CurrentTokenOwnerships } from '../model/CurrentTokenOwnerships';
import { capitalizeFirstLetter, convertMoveUpAddressToEthMode, shortAddress } from '../utils/StringHelper';
import { timeFromNow } from '../utils/TimeHelper';
import { MOVEUP_DECIMALS, getTransactionAmountAll, getTransactionCounterparty, getTransactionFunction, getTransactionFunctionRoute, getTransactionGas, getTransactionSender } from '../utils/TransactionsUtils';
import BigNumber from 'bignumber.js';

export enum AddressInfoTabs {
    transactions = 0,
    coins = 1,
    tokens = 2,
    resources = 3,
    modules = 4,
    info = 5,
}

export function AddressInfoPage() {
    return (
        <AddressContextProvider>
            <AddressInfoDetail />
        </AddressContextProvider>
    )
}

export function AddressInfoDetail() {
    const params = useParams();
    const address = params.addressId;
    const navigate = useNavigate();
    const location = useLocation();
    const {
        showLoading,
        ethBalance,
        txs,
        coins,
        tokens,
        accountResources,
        packages,
        accountInfo,
        currentTotalPage,
        onPageChange,
    } = useAddressContext();
    const mepBalance = useGetAccountMoveUpBalance(address ?? '');

    const [tabValue, setTabValue] = useState<number>(AddressInfoTabs.transactions);

    useEffect(() => {
        /*
         * Redirect to address/:addressId/transactions
         */
        const tabSplit = location.pathname.split('/') ?? [];
        let isSupportTab = false;
        let supportedTab = '';
        for (const r of tabSplit) {
            if (Object.values(AddressInfoTabs).includes(r)) {
                supportedTab = r;
                isSupportTab = true
            }
        }

        if (isSupportTab) {
            setTabValue(AddressInfoTabs[supportedTab as keyof typeof AddressInfoTabs])
        } else {
            navigate(`${AddressInfoTabs[0]}`);
        }
    }, [location.pathname, navigate]);

    useEffect(() => {
        onPageChange(tabValue, 1);
    }, [onPageChange, tabValue]);

    return (
        <div className="flex flex-col min-h-screen">
            <div className="h-16 md:h-5" />
            <div className='md:pl-4'>
                <BackButton />
            </div>
            <div className="h-7 md:h-2" />
            <div className="flex flex-row items-end md:flex-col md:px-4">
                <div className="flex flex-col md:w-full ">
                    <div className="text-left text-4xl font-extrabold md:w-full md:text-4xl">Account</div>
                    <div className="h-2" />
                    <div className="md:w-full break-words">
                        <span className="text-gray-700 text-sm font-semibold">{address}</span>
                        <CopyIcon text={address ?? ''} />
                    </div>
                </div>
                {isMobile && <div className="md:h-5" />}
                {!isMobile && <div className="flex-1" />}
                <div className="flex flex-col bg-white rounded-2xl py-4 pr-4 pl-10 items-end md:w-full md:items-center">
                    <div className="text-gray-600 text-xs font-bold">BALANCE</div>
                    <div className="text-gray-900 text-lg font-bold">{`${mepBalance ?? 0} ETH`}</div>
                </div>
            </div>
            <div className="h-7" />
            <div className="w-full h-full bg-white rounded-2xl shadow-md overflow-hidden">
                <div className='px-5'>
                    <AppTabs
                        value={tabValue}
                        variant="scrollable"
                        onChange={(_, newValue) => {
                            setTabValue(newValue);
                            navigate(`${AddressInfoTabs[newValue]}`);
                        }}
                    >
                        <AppTab label={`${capitalizeFirstLetter(AddressInfoTabs[0])}`} />
                        <AppTab label={`${capitalizeFirstLetter(AddressInfoTabs[1])}`} />
                        <AppTab label={`${capitalizeFirstLetter(AddressInfoTabs[2])}`} />
                        <AppTab label={`${capitalizeFirstLetter(AddressInfoTabs[3])}`} />
                        <AppTab label={`${capitalizeFirstLetter(AddressInfoTabs[4])}`} />
                        {!!accountInfo.authentication_key && <AppTab label={`${capitalizeFirstLetter(AddressInfoTabs[5])}`} />}
                    </AppTabs>
                </div>
                {
                    !showLoading && <Routes>
                        <Route path={`/${AddressInfoTabs[0]}`} element={
                            <TabPanel value={tabValue} index={AddressInfoTabs.transactions}>
                                <TransactionsPanel txs={txs} />
                            </TabPanel>
                        } />
                        <Route path={`/${AddressInfoTabs[1]}`} element={
                            <TabPanel value={tabValue} index={AddressInfoTabs.coins}>
                                <AddressCoins coins={coins} />
                            </TabPanel>
                        } />
                        <Route path={`/${AddressInfoTabs[2]}`} element={
                            <TabPanel value={tabValue} index={AddressInfoTabs.tokens}>
                                <AddressTokens tokens={tokens} />
                            </TabPanel>
                        } />
                        <Route path={`/${AddressInfoTabs[3]}`} element={
                            <TabPanel value={tabValue} index={AddressInfoTabs.resources}>
                                <AddressResources resources={accountResources} />
                            </TabPanel>
                        } />
                        <Route path={`/${AddressInfoTabs[4]}/*`} element={
                            <TabPanel value={tabValue} index={AddressInfoTabs.modules}>
                                <AddressModules packages={packages} />
                            </TabPanel>
                        } />
                        <Route path={`/${AddressInfoTabs[5]}`} element={
                            <TabPanel value={tabValue} index={AddressInfoTabs.info}>
                                <AddressInfo accountInfo={accountInfo} />
                            </TabPanel>
                        } />
                    </Routes>
                }
                {showLoading && <div className='my-28 flex justify-center items-center'><CircularProgress /></div>}
            </div>
            <div className="h-7 md:h-5" />
            {currentTotalPage > 1 && <PaginationRounded totalCount={currentTotalPage} onChange={(page) => onPageChange(tabValue, page)} />}
        </div>
    );
}

function AddressCoins(params: { coins: CoinBalances[] }) {
    if (!params.coins || params.coins.length === 0) return <NoDataFound />

    return (
        <div className='px-5 md:overflow-x-auto'>
            <div className='h-16 flex flex-row items-center'>
                <div className='text-sm font-semibold flex-1 text-gray-600 md:w-44 md:flex-none'>NAME</div>
                <div className='text-sm font-semibold flex-1 text-gray-600 md:w-56 md:flex-none'>AMOUNT</div>
                <div className='text-sm font-semibold flex-[2_2_0%] text-gray-600 md:w-56 md:flex-none'>COIN TYPE</div>
            </div>
            {
                params.coins.map((coin, index) => {
                    return <div className='h-16 flex flex-col' key={`address-info-page-coins-${index}`}>
                        <div className='w-full h-px bg-gray-200 md:w-149' />
                        <div className='h-16 flex flex-row items-center'>
                            <div className='flex-1 flex flex-row items-center text-gray-700 text-sm font-semibold md:w-44 md:flex-none'>
                                <div>{coin.coinInfos.symbol}</div>
                            </div>
                            <div className='flex-1 flex flex-row items-center text-gray-700 text-sm font-semibold md:w-56 md:flex-none'>
                                <div>{new BigNumber(coin.amount).shiftedBy(-MOVEUP_DECIMALS).toString(10)}</div>
                            </div>
                            <div className='flex-[2_2_0%] flex flex-row items-center text-indigo-900 text-sm font-semibold hover:text-indigo-600 md:w-56 md:flex-none'>
                                <div>{coin.coinType}</div>
                            </div>
                        </div>
                    </div>
                })
            }
        </div>
    );
}

function AddressTokens(params: { tokens: CurrentTokenOwnerships[] }) {
    if (!params.tokens || params.tokens.length === 0) return <NoDataFound />

    return (
        <div className='px-5 md:overflow-x-auto'>
            <div className='h-16 flex flex-row items-center'>
                <div className='text-sm font-semibold flex-1 text-gray-600 md:w-44 md:flex-none'>NAME</div>
                <div className='text-sm font-semibold flex-1 text-gray-600 md:w-56 md:flex-none'>COLLECTION</div>
                <div className='text-sm font-semibold flex-[2_2_0%] text-gray-600 md:w-44 md:flex-none'>STORE</div>
                <div className='text-sm font-semibold flex-1 text-gray-600 md:w-44 md:flex-none'>INDEX</div>
                <div className='text-sm font-semibold flex-1 text-gray-600 md:w-44 md:flex-none'>AMOUNT</div>
            </div>
            {
                params.tokens.map((token, index) => {
                    return <div className='h-16 flex flex-col' key={`address-info-page-tokens-${index}`}>
                        <div className='w-full h-px bg-gray-200 md:w-228' />
                        <div className='h-16 flex flex-row items-center'>
                            <Link
                                to={`/token/${token.tokenDataIdHash}`}
                                className='flex-1 flex flex-row items-center text-indigo-900 text-sm font-semibold hover:text-indigo-600 md:w-44 md:flex-none'
                            >
                                <div>{token.name}</div>
                            </Link>
                            <div className='flex-1 flex flex-row items-center text-gray-700 text-sm font-semibold md:w-56 md:flex-none'>
                                <div>{token.collectionName}</div>
                            </div>
                            <div className='flex-[2_2_0%] flex flex-row items-center text-gray-700 text-sm font-semibold md:w-44 md:flex-none'>
                                <div>{token.tableType}</div>
                            </div>
                            <div className='flex-1 flex flex-row items-center text-gray-700 text-sm font-semibold md:w-44 md:flex-none'>
                                <div>{token.propertyVersion}</div>
                            </div>
                            <div className='flex-1 flex flex-row items-center text-gray-700 text-sm font-semibold md:w-44 md:flex-none'>
                                <div>{token.amount}</div>
                            </div>
                        </div>
                    </div>
                })
            }
        </div>
    );
}

function AddressResources(params: { resources: Types.MoveResource[] }) {
    const { resources } = params;
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange = useCallback((panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    }, []);


    if (resources.length === 0) {
        return <NoDataFound />
    }
    return (<div className='max-h-screen overflow-y-scroll'>
        {resources.map((resource, index) => {
            const key = `address-info-resource-${index}-${resource.type}`;
            return (
                <div key={key}>
                    <AppAccordion expanded={expanded === key} onChange={handleChange(key)}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <div className='w-full flex flex-row fx-5'>
                                <div>Type</div>
                                <div className='w-40 md:w-5' />
                                <div className='md:flex-1 md:whitespace-normal md:overflow-auto md:break-all'>{resource.type}</div>
                                <div className='flex-1 md:flex-none md:w-5' />
                                {expanded === key && <img src='/images/icon-arrow-up.svg' width={16} alt="" />}
                                {expanded !== key && <img src='/images/icon-ios-arrow-down.svg' width={16} alt="" />}
                            </div>
                        </AccordionSummary>
                        <div className='h2' />
                        <AppAccordionDetails>
                            <pre>{JSON.stringify(resource, null, 2)}</pre>
                        </AppAccordionDetails>
                    </AppAccordion>
                </div>
            );
        })}
    </div>);
}

function AddressInfo(params: { accountInfo: Types.AccountData }) {
    return (<div className='px-5 md:overflow-x-auto'>
        <div className="h-14 flex flex-row items-center md:w-149">
            <div className="w-60 text-gray-600 text-sm font-semibold md:w-40">Nonce: </div>
            <div className="flex flex-row text-gray-700 text-sm font-semibold md:flex-1">
                {params.accountInfo.sequence_number}
            </div>
        </div>
        <div className="w-full h-px bg-gray-200 md:w-149" />
        <div className="h-14 flex flex-row items-center md:w-149">
            <div className="w-60 text-gray-600 text-sm font-semibold md:w-40">Authentication Key: </div>
            <div className="flex flex-row text-gray-700 text-sm font-semibold md:flex-1">
                {convertMoveUpAddressToEthMode(params.accountInfo.authentication_key)}
            </div>
        </div>
    </div>);
}

export function TransactionsPanel(params: { txs: Types.Transaction[] }) {

    const transactions = params.txs;

    if (transactions.length === 0) {
        return <NoDataFound />;
    }

    return (
        <div className='flex flex-col px-5 md:overflow-x-auto'>
            <TransactionsTitle />
            {transactions && transactions.map((tx: Types.Transaction, index: number) => {
                const version = 'version' in tx && tx.version;
                const sender = convertMoveUpAddressToEthMode(getTransactionSender(tx));
                const timeAgo = timeFromNow(`${'timestamp' in tx && tx.timestamp}`);
                const senderShort = shortAddress(sender);
                const sendTo = convertMoveUpAddressToEthMode(getTransactionCounterparty(tx)?.address ?? '');
                const sendToShort = shortAddress(sendTo);
                const func = getTransactionFunction(tx);
                const funcRoute = getTransactionFunctionRoute(tx);
                const amount = getTransactionAmountAll({ transaction: tx });
                const gas = getTransactionGas(tx);

                return (
                    <div key={`address-info-page-tx-${index}`}>
                        <TransactionsItem
                            version={`${version}`}
                            timeAgo={timeAgo}
                            sender={sender}
                            senderShort={senderShort}
                            sendTo={sendTo}
                            sendToShort={sendToShort}
                            funcStr={func}
                            funcRoute={funcRoute}
                            amount={amount ?? ''}
                            gas={gas}
                        />
                    </div>
                )
            })}
        </div>
    );
}
