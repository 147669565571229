import { Box } from "@mui/material";
import { useState } from "react";
import { useSnackbar } from "../context/SnackbarContext";
import { useWallet } from "../context/WalletContext";
import { WalletReadyState } from "../model/WalletContextState";
import { AppDialog } from "./AppDialog";
import { AppLinearProgress } from "./AppLinearProgress";
import { CloseButton } from "./CloseButton";

type WalletsDialogProps = {
    handleClose: () => void;
    modalOpen: boolean;
    networkSupport?: string;
};

export function WalletsDialog({ handleClose, modalOpen }: WalletsDialogProps) {
    const { wallets, connect } = useWallet();

    const [isFailure, setIsFailure] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onInstall = (installUrl: string) => {
        window.open(installUrl, '_blank', 'noopener,noreferrer')
    }

    return (
        <AppDialog onClose={handleClose} open={modalOpen} style={{ borderRadius: 16 }}>
            <div className="py-10 w-125">
                <div className="flex items-center text-gray-700 text-lg font-bold px-10">
                    Connect Wallet
                    <div className="flex-1" />
                    <CloseButton onClose={handleClose} />
                </div>
                {/* Connection failed */}
                {
                    isFailure && (
                        <div className="min-h-dialogDefault flex flex-col items-center justify-center">
                            <div className="text-2xl text-gray-600 font-semibold">Connection failed</div>
                            <div className="h-8" />
                            <div
                                onClick={() => setIsFailure(false)}
                                className="bg-gray-100 w-28 h-11 hover:bg-gray-200 rounded-lg cursor-pointer flex items-center justify-center"
                            >
                                Try Again
                            </div>
                        </div>
                    )
                }
                {/* Loading */}
                {
                    !isFailure && isLoading && (
                        <div className="min-h-dialogDefault flex flex-col items-center justify-center">
                            <div className="max-w-70 text-gray-900 text-2xl font-semibold text-center">Asking for access to Metamask Wallet</div>
                            <div className="h-8" />
                            <Box sx={{ width: '50%' }}>
                                <AppLinearProgress />
                            </Box>
                        </div>
                    )
                }
                {/* Wallet List */}
                {
                    <div>
                        <div className="h-4" />
                        {
                            !isFailure && !isLoading && (
                                wallets.map((wallet, index) => (
                                    <div
                                        key={`wallets-modal-${index}`}
                                        className="w-f flex h-18 items-center px-10 hover:bg-purple-200"
                                    >
                                        <img className="w-6 h-6" src={wallet.icon} alt="" />
                                        <div className="w-4" />
                                        <div>{wallet.name}</div>
                                        <div className="flex-1" />
                                        {
                                            wallet.readyState() === WalletReadyState.Installed && (

                                                <div
                                                    onClick={() => {
                                                        setIsLoading(true);
                                                        wallet.connect()
                                                            .then(addressInfo => {
                                                                console.log('then address: ', addressInfo.address);
                                                                connect(wallet, addressInfo.address);
                                                            })
                                                            .catch(e => {
                                                                console.log('error address: ', e);
                                                                setIsFailure(true);
                                                            })
                                                            .finally(() => {
                                                                console.log('finally');
                                                                setIsLoading(false);
                                                            });
                                                    }}
                                                    className="bg-purple-500 h-8 px-3 py-2 rounded-xl text-white cursor-pointer flex items-center text-sm font-semibold"
                                                >
                                                    Connect
                                                </div>
                                            )
                                        }
                                        {
                                            wallet.readyState() === WalletReadyState.NotDetected && (
                                                <div
                                                    onClick={() => onInstall(wallet.url)}
                                                    className="text-sm text-purple-500 font-semibold cursor-pointer pr-4"
                                                >
                                                    Install
                                                </div>
                                            )
                                        }
                                    </div>
                                ))
                            )
                        }
                    </div>
                }
            </div>
        </AppDialog>
    );
}