import { Network } from "@anyvm/moveup-sdk";

export interface WalletContextState {
    connected: boolean;
    isLoading: boolean;
    account: AccountInfo | null;
    network: NetworkInfo | null;
    connect(wallet: Wallet, address: string): void;
    disconnect(): void;
    wallet: WalletInfo | null;
    wallets: ReadonlyArray<Wallet>;
}

export declare type AccountInfo = {
    address: string;
    publicKey?: string | string[];
    minKeysRequired?: number;
    ansName?: string | null;
};

export declare type NetworkInfo = {
    name: Network;
    chainId?: string;
    url?: string;
};

export declare type WalletInfo = {
    name: string;
    icon: string;
    url: string;
};

export declare type Wallet = {
    readyState: () => WalletReadyState;
    name: string;
    icon: `data:image/${"svg+xml" | "webp" | "png" | "gif"};base64,${string}`;
    url: string;
    provider: any;
    connect(): Promise<any>;
    disconnect: () => Promise<any>;
    network: () => Promise<any>;
    onNetworkChange(callback: any): Promise<any>;
    onAccountChange(callback: any): Promise<any>;
};

export enum WalletReadyState {
    /**
     * User-installable wallets can typically be detected by scanning for an API
     * that they've injected into the global context. If such an API is present,
     * we consider the wallet to have been installed.
     */
    Installed = "Installed",
    NotDetected = "NotDetected",
    /**
     * Loadable wallets are always available to you. Since you can load them at
     * any time, it's meaningless to say that they have been detected.
     */
    Loadable = "Loadable",
    /**
     * If a wallet is not supported on a given platform (eg. server-rendering, or
     * mobile) then it will stay in the `Unsupported` state.
     */
    Unsupported = "Unsupported"
}