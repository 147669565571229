import React, { ChangeEvent, Children, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { CircularProgress, Menu } from '@mui/material';
import { useAppContext } from '../../context/AppContext';

import { Link, Outlet } from 'react-router-dom';
import './IndexPage.scss';
import { shortAddress } from '../../utils/StringHelper';
import { Types } from '@anyvm/moveup-sdk';
import Web3 from 'web3';
import { apiService } from '../../utils/httpHelper';
import { APIResData } from '../../utils/types';
import { CustomTx } from '../TransactionsPage';
import { timeFromNow } from '../../utils/TimeHelper';

interface MoveUpStatus {
    latestBlock?: string;
    latestTransaction?: string;
    tps?: string;
    latestBlockDuration?: string;
}

export default function IndexPage() {
    const appContext = useAppContext();
    const [blocks, setBlocks] = useState<any[]>([])
    const [searching, setSearching] = useState<boolean>(false)
    const [ethPrice, setEthPrice] = useState<number>(0)
    const [secoundValue, setSecoundValue] = useState<number>(new Date().getTime() / 1000)
    const [transactions, setTxns] = useState<any[]>([])
    const [noTransactions, setNoTxns] = useState<boolean>(false)
    const [effectiveSearchedRes, setEffectiveSearchedRes] = useState<any[]>([])
    const [moveUpStatus, setMoveUpStatus] = useState<MoveUpStatus>({})
    const debounceTimer = useRef<any>(null);
    const seachRef = useRef<any>(null);

    const [isMobileMode, setIsMobileMode] = useState<boolean>(false)
    useEffect(() => {
        if (window.matchMedia("(max-width: 767px)").matches) {
            setIsMobileMode(true)
        } else {
            setIsMobileMode(false)
        }
        const intervalHandler = setInterval(() => {
            setSecoundValue(new Date().getTime() / 1000)
        }, 1000)
        return () => {
            clearInterval(intervalHandler)
        }
    }, [])
    const doSearchData = async (searchContent: string) => {
        const res = [];
        setSearching(true)
        if (!searchContent.startsWith('0x') && !isNaN(Number(searchContent))) {
            const numberContent = Number(searchContent);
            try {
                const resBlockByHeight = await appContext.provider.getBlockByHeight(numberContent);
                if (resBlockByHeight) {
                    res.push({ type: 'Block', data: resBlockByHeight, search: searchContent })
                }
            } catch (e: any) { }
            try {
                const resBlockByVersion = await appContext.provider.getBlockByVersion(numberContent);
                if (resBlockByVersion) {
                    res.push({ type: 'Block with txn version', data: resBlockByVersion, search: searchContent })
                }
            } catch (e: any) { }
        }
        if (!searchContent.startsWith('0x')) {
            const searchContent2 = `0x${searchContent}`;
            try {
                const resAccount = await appContext.provider.getAccount(searchContent2);
                if (resAccount) {
                    res.push({ type: 'Account', data: resAccount, search: searchContent })
                }
            } catch (e: any) { }
        } else {
            try {
                const resAccount = await appContext.provider.getAccount(searchContent);
                if (resAccount) {
                    res.push({ type: 'Account', data: resAccount, search: searchContent })
                }
            } catch (e: any) { }
        }
        try {
            const resTx = await appContext.provider.getTransactionByHash(searchContent);
            if (resTx) {
                res.push({ type: 'Transaction', data: resTx, search: searchContent })
            }
        } catch (e: any) { }
        setSearching(false)
        if (res.length <= 0 && Web3.utils.checkAddressChecksum(searchContent)) {
            res.push({ type: 'Account', data: searchContent, search: searchContent })
        }
        if (res.length <= 0) {
            res.push({ type: 'No Results', data: undefined, search: undefined })
        }
        if (res && res.length > 0) {
            setEffectiveSearchedRes(res)
        }
    }
    const loadLastestEthPrice = async () => {
        setEthPrice(1678.093912)
    }
    const loadLastestData = async () => {
        const moveUpStatusData: MoveUpStatus = {};

        const { data } = await apiService.get<APIResData<CustomTx>>('/tx/user', { skip: 0, limit: 8 })
        if (!data || data.length <= 0) {
            setNoTxns(true)
        } else {
            const reqAll: Promise<Types.Transaction>[] = [];
            data.forEach(ver => {
                reqAll.push(appContext.provider.getTransactionByVersion(Number(ver.version)))
            })
            const txs = await Promise.all(reqAll)
            setTxns(txs)
            const firstTx = (txs[0] as any)
            const lastTx = (txs[txs.length - 1] as any)
            if (lastTx.success && firstTx.success) {
                const tps = txs.length / (lastTx.timestamp - firstTx.timestamp) * 1000000
                const tpsRounded = Math.abs(tps).toFixed(1);
                moveUpStatusData.tps = tpsRounded;
            }
        }
        const ledgerInfo = await appContext.provider.getLedgerInfo();
        moveUpStatusData.latestBlock = Number(ledgerInfo.block_height).toLocaleString();
        moveUpStatusData.latestTransaction = Number(ledgerInfo.ledger_version).toLocaleString();
        setMoveUpStatus(moveUpStatusData)
        const latestBlockHeight = Number(ledgerInfo.block_height || 0);
        const blockReqList = [];
        for (let index = latestBlockHeight; index > Math.max(0, (latestBlockHeight - 8)); index--) {
            blockReqList.push(appContext.provider.getBlockByHeight(index, false))
        }
        const resBlocks = await Promise.all(blockReqList);
        const latestBlockDuration = Math.abs(Number(resBlocks[0].block_timestamp) - Number(resBlocks[1].block_timestamp)) / 1000000;
        moveUpStatusData.latestBlockDuration = latestBlockDuration.toFixed(1);
        setBlocks(resBlocks)
    }

    const renderBlockList = useMemo(() => {
        if (blocks.length <= 0) {
            return <div className='min-h-mainPanel flex flex-col items-center justify-center'><CircularProgress /></div>
        }
        return blocks.map((blk, index) => {
            const tnxCount = blk.last_version - blk.first_version - 1;
            return <div className='flex flex-row p-5 w-full' key={`index-page-block-${index}`}>
                <div className='bg-grey-100 w-12 h-12 text-grey-600 p-4 text-center leading-4 rounded mr-5 font-bold'>Bk</div>
                <div>
                    <Link className='text-indigo-900' to={`/block/${blk.block_height}/overview`}>{blk.block_height}</Link>
                    <div className='mt-2 text-grey-600'>
                        {timeFromNow(blk.block_timestamp)}{' '}
                        {tnxCount}{' '}{tnxCount > 1 ? 'txns' : 'txn'}
                    </div>
                </div>
            </div>
        });
    }, [blocks, secoundValue])
    const renderTxList = useMemo(() => {
        if (transactions.length <= 0 && !noTransactions) {
            return <div className='min-h-mainPanel flex flex-col items-center justify-center'><CircularProgress /></div>
        }
        if (noTransactions) {
            return <div></div>
        }
        return transactions.sort((a, b) => b.version - a.version).map((tx, index) => {
            return <div className='flex flex-row p-5 w-full' key={`index-page-tx-${index}`}>
                <div className='bg-grey-100 w-12 h-12 text-grey-600 p-4 text-center leading-4 rounded mr-5 font-bold'>Tx</div>
                <div className='flex-1'>
                    <Link className='text-indigo-900 hash-text' to={`/tx/${tx.hash}`}>{shortAddress(tx.hash)}</Link>
                    <div className='mt-2 text-grey-600'>
                        {timeFromNow(tx.timestamp)}
                    </div>
                </div>
            </div>
        });
    }, [transactions, secoundValue, noTransactions])

    useEffect(() => {
        loadLastestData()
        loadLastestEthPrice()
    }, [])
    function getLinkByData(r: { type: string; search: string; data: any }): string {
        switch (r.type) {
            case 'Block':
            case 'Block with txn version':
                return `/block/${(r.data as Types.Block).block_height}/overview`;
            case 'Account':
                return `/address/${r.search}/transactions`;
            case 'No Results':
                return ``;
            case 'Transaction':
            default:
                return `/tx/${(r.data as Types.Transaction).hash}`;
        }
    }
    function onSearchInputed(event: ChangeEvent<HTMLInputElement>): void {
        if (!debounceTimer.current) {
            debounceTimer.current = setTimeout(() => {
                debounceTimer.current = null;
                doSearchData(event.target.value)
            }, 1500);
        } else {
            clearTimeout(debounceTimer.current);
            debounceTimer.current = setTimeout(() => {
                debounceTimer.current = null;
                doSearchData(event.target.value)
            }, 1500);
        }
    }

    return (
        <div className="App">
            <div className='text-h1 mt-20 md:mt-8 md:pr-16'>AnyVM Explorer</div>
            <div className='bg-white h-16 mt-8 rounded-2xl flex flex-row items-center p-4 md:w-full' style={isMobileMode ? { width: '100%' } : { width: '620px' }} ref={seachRef}>
                {searching ? <CircularProgress size={20} className="m-1" /> : <img src='/images/search.svg' width={28} height={28} />}
                <input
                    onChange={onSearchInputed}
                    className='flex-1 outline-none ml-2 text-placeholder'
                    placeholder={isMobileMode ? 'Addr / Txn Hash / Block / Token' : 'Search by Address / Txn Hash / Block / Token'} />
                <Menu
                    id="basic-menu"
                    className='mt-1 rounded-md'
                    anchorEl={seachRef.current}
                    open={(seachRef.current && effectiveSearchedRes && effectiveSearchedRes.length > 0)}
                    onClose={() => setEffectiveSearchedRes([])}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}

                >
                    {effectiveSearchedRes.map((r: { type: string, search: string, data: Types.Block | Types.Transaction | Types.AccountData }) => {
                        const route1 = getLinkByData(r)
                        if (!route1) {
                            return <div className='flex flex-row justify-stretch' style={isMobileMode ? { width: '100%' } : { width: '620px' }} onClick={() => setEffectiveSearchedRes([])}>
                                <div className='cursor-pointer hover:bg-gray-100 h-12 leading-8 px-4 py-2 w-full' >
                                    {r.type}
                                </div>
                            </div>
                        }
                        return <div className='flex flex-row justify-stretch' style={isMobileMode ? { width: '100%' } : { width: '620px' }}>
                            <Link to={route1} className='cursor-pointer hover:bg-gray-100 h-12 leading-8 px-4 py-2 w-full' >
                                {r.type}:{' '}{shortAddress(r.search)}
                            </Link>
                        </div>
                    })}
                </Menu>
            </div>
            <div className='w-full bg-white mt-8 rounded-2xl box-shadow md:hidden block'>
                <div className='w-full flex flex-row'>
                    <div className='sum-item-height flex-1 flex flex-row items-start p-6'>
                        <img src='/images/eth-icon.svg' width={36} height={36} />
                        <div className='bg-purple sum-divider rounded-sm'></div>
                        <div className=''>
                            <div className='text-head-grey text-dark-900'>Ether Price</div>
                            <div className='text-h3'>$1,879.428272 USD</div>
                        </div>
                    </div>
                    <div className='sum-item-height w-0 border-r border-solid border-divider-grey self-stretch'></div>
                    <div className='sum-item-height flex-1 flex flex-row items-start p-6'>
                        <img src='/images/blocks.svg' width={32} height={32} />
                        <div className='bg-purple sum-divider rounded-sm'></div>
                        <div className='flex flex-col justify-between'>
                            <div className='text-head-grey text-dark-900'>Latest Block</div>
                            {!moveUpStatus.latestBlock ? <CircularProgress size={16} className="mt-4" /> : <div className='text-h3'>
                                {moveUpStatus.latestBlock} {!!moveUpStatus.latestBlockDuration && <span className='font-light'>({moveUpStatus.latestBlockDuration}s)</span>}
                            </div>}
                        </div>
                    </div>
                    <div className='sum-item-height w-0 border-r border-solid border-divider-grey self-stretch'></div>
                    <div className='sum-item-height flex-1 flex flex-row items-start p-6'>
                        <img src='/images/gas.svg' width={32} height={32} />
                        <div className='bg-purple sum-divider rounded-sm'></div>
                        <div className=''>
                            <div className='text-head-grey text-dark-900'>Current Gas Price</div>
                            <div className='text-h3'>0.0025 Gwei</div>
                        </div>
                    </div>
                </div>
                <div className='h-0 w-full border-b border-solid border-divider-grey'></div>
                <div className='w-full flex flex-row'>
                    <div className='sum-item-height flex-1 flex flex-row items-start p-6'>
                        <img src='/images/logo-icon.svg' width={36} height={36} />
                        <div className='bg-purple sum-divider rounded-sm'></div>
                        <div className=''>
                            <div className='text-head-grey text-dark-900'>MEP Price</div>
                            <div className='text-h3'>$566.32 USD</div>
                        </div>
                    </div>
                    <div className='sum-item-height w-0 border-r border-solid border-divider-grey'></div>
                    <div className='sum-item-height flex-1 flex flex-row items-start p-6'>
                        <img src='/images/txs.svg' width={32} height={32} />
                        <div className='bg-purple sum-divider rounded-sm'></div>
                        <div className=''>
                            <div className='text-head-grey text-dark-900'>Transactions</div>
                            {!moveUpStatus.latestTransaction ? <CircularProgress size={16} className="mt-4" /> :
                                <div className='text-h3'>{moveUpStatus.latestTransaction} {!!moveUpStatus.tps && <span className='font-light'>({moveUpStatus.tps} TPS)</span>}</div>}
                        </div>
                    </div>
                    <div className='sum-item-height w-0 border-r border-solid border-divider-grey'></div>
                    <div className='sum-item-height flex-1 flex flex-row items-start p-6'>
                        <img src='/images/global.svg' width={32} height={32} />
                        <div className='bg-purple sum-divider rounded-sm'></div>
                        <div className=''>
                            <div className='text-head-grey text-dark-900'>MEP MARCKET CAP</div>
                            <div className='text-h3'>$ 2,996,340.03</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full bg-white mt-8 rounded-2xl box-shadow md:block hidden border-solid border border-border-panel'>
                <div className='w-full flex flex-row'>
                    <div className='sum-item-height flex-1 flex flex-row items-start p-6 shrink-0 max-w-half'>
                        <div className=''>
                            <div className='text-head-grey text-dark-900'>Ether Price</div>
                            <div className='text-h3'><span className='text-sm font-light'>$</span>{ethPrice.toFixed(2)} <span className='text-sm font-light'>USD</span></div>
                        </div>
                    </div>
                    <div className='sum-item-height w-0 border-r border-solid border-divider-grey self-stretch'></div>
                    <div className='sum-item-height flex-1 flex flex-row items-start p-6 shrink-0 max-w-half'>
                        <div className=''>
                            <div className='text-head-grey text-dark-900'>MEP Price</div>
                            <div className='text-h3'><span className='text-sm font-light'>$</span>566.32 <span className='text-sm font-light'>USD</span></div>
                        </div>
                    </div>
                </div>
                <div className='h-0 w-full border-b border-solid border-divider-grey'></div>
                <div className='w-full flex flex-row'>
                    <div className='sum-item-height flex-1 flex flex-row items-start p-6 shrink-0 max-w-half'>
                        <div className='flex flex-col justify-between'>
                            <div className='text-head-grey text-dark-900'>Latest Block</div>
                            {!moveUpStatus.latestBlock ? <CircularProgress size={16} className="mt-4" /> :
                                <>
                                    <div className='text-h3'>{moveUpStatus.latestBlock} </div>
                                    {!!moveUpStatus.latestBlockDuration && <div className='font-light text-sm -mt-1'>({moveUpStatus.latestBlockDuration}s)</div>}
                                </>
                            }
                        </div>
                    </div>
                    <div className='sum-item-height w-0 border-r border-solid border-divider-grey self-stretch'></div>
                    <div className='sum-item-height flex-1 flex flex-row items-start p-6 shrink-0 max-w-half'>
                        <div className=''>
                            <div className='text-head-grey text-dark-900'>Transactions</div>
                            {!moveUpStatus.latestTransaction ? <CircularProgress size={16} className="mt-4" /> :
                                <>
                                    <div className='text-h3'>{moveUpStatus.latestTransaction} </div>
                                    {!!moveUpStatus.tps && <div className='font-light text-sm -mt-1'>({moveUpStatus.tps} TPS)</div>}
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className='h-0 w-full border-b border-solid border-divider-grey'></div>
                <div className='w-full flex flex-row'>
                    <div className='sum-item-height flex-1 flex flex-row items-start p-6 shrink-0 max-w-half'>
                        <div className=''>
                            <div className='text-head-grey text-dark-900'>Current Gas Price</div>
                            <div className='text-h3'>0.0025 <span className='text-sm font-light'>Gwei</span></div>
                        </div>
                    </div>
                    <div className='sum-item-height w-0 border-r border-solid border-divider-grey'></div>
                    <div className='sum-item-height flex-1 flex flex-row items-start p-6 shrink-0 max-w-half'>
                        <div className=''>
                            <div className='text-head-grey text-dark-900'>MEP MARCKET CAP</div>
                            <div className='text-h3'><span className='text-sm font-light'>$</span>2,996,340.03</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-10 flex flex-row mb-10 md:flex-col'>
                <div className='flex bg-white flex-col rounded-3xl flex-1 basis-0 shrink-0 panel-height box-shadow2 border border-solid border-border-panel mr-4 md:mr-0 md:mb-2'>
                    <div className='h-12 text-panel-head text-gray-900 px-5 py-3 w-full'>Latest Blocks</div>
                    <div className='flex-1 items-center justify-center flex flex-col overflow-scroll hide-scrollbar'>{renderBlockList}</div>
                    <Link to={'/blocks'} className='h-14 font-8 text-dark-700 w-full text-center border-t border-solid border-border-grey2 leading-10 p-2 cursor-pointer'>View all blocks</Link>
                </div>
                <div className='flex bg-white flex-col rounded-3xl flex-1 basis-0 shrink-0 panel-height box-shadow2 border border-solid border-border-panel mr-4 md:mr-0 md:mb-2'>
                    <div className='h-12 text-panel-head text-gray-900 px-5 py-3 w-full'>Latest Transactions</div>
                    <div className='flex-1 items-center justify-center flex flex-col overflow-scroll hide-scrollbar'>{renderTxList}</div>
                    <Link to={'/txs'} className='h-14 font-8 text-dark-700 w-full text-center border-t border-solid border-border-grey2 leading-10 p-2 cursor-pointer'>View all transactions</Link>

                </div>
                <div className='flex bg-white flex-col rounded-3xl flex-1 basis-0 shrink-0 panel-height box-shadow2 border border-solid border-border-panel'>
                    <div className='h-12 text-panel-head text-gray-900 px-5 py-3 w-full'>Latest L1→L2 Transactions</div>
                    <div className='flex-1 items-center justify-center flex flex-col overflow-scroll hide-scrollbar'>{renderTxList}</div>
                    <Link to={'/txs'} className='h-14 font-8 text-dark-700 w-full text-center border-t border-solid border-border-grey2 leading-10 p-2 cursor-pointer'>View all transactions</Link>
                </div>
            </div>
        </div>
    );
}
