import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { useSnackbar } from "../context/SnackbarContext";
import { transformCode } from "../utils/ModuleUtils";
import { InfoIcon } from "./InfoIcon";
import { solarizedLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';

export function CodeSnippet({ bytecode }: { bytecode: string }) {
    const showSnackbar = useSnackbar();

    const sourceCode = bytecode === "0x" ? undefined : transformCode(bytecode);

    return (
        <div>
            <div className='flex flex-row'>
                <div className='text-base font-bold flex flex-row'>Code <InfoIcon tooltipTitle='Please be aware that this code was provided by the owner and it could be different to the real code on blockchain. We can not not verify it.' /></div>
                <div className='flex-1' />
                <div
                    className='text-base font-bold cursor-pointer text-purple-500'
                    onClick={() => {
                        navigator.clipboard.writeText(sourceCode ?? '');
                        showSnackbar('Copied');
                    }}>
                    Copy Code
                </div>
            </div>
            {
                sourceCode && (
                    <div className="px-6 max-h-[38rem] overflow-y-scroll">
                        <div className='mt-4 mb-6 text-base text-gray-600'>The source code is plain text uploaded by the deployer, which can be different from the actual bytecode.</div>
                        <SyntaxHighlighter
                            language="rust"
                            customStyle={{ margin: 0, backgroundColor: "#EDEDF6", borderRadius: '8px' }}
                            showLineNumbers
                            style={solarizedLight}
                        >
                            {sourceCode}
                        </SyntaxHighlighter>
                    </div>
                )
            }
            {
                !sourceCode && <div className='mt-4 mb-6 text-base text-gray-600'>Unfortunately, the source code cannot be shown because the package publisher has chosen not to make it available</div>
            }
        </div>
    );
}