export enum ResponseErrorType {
    NOT_FOUND = "Not found",
    UNHANDLED = "Unhandled",
    TOO_MANY_REQUESTS = "To Many Requests",
}

export class ResponseError extends Error {
    type: ResponseErrorType;

    constructor(type: ResponseErrorType, message?: string) {
        super(message);
        this.type = type;
    }
}

export async function withResponseError<T>(promise: Promise<T>): Promise<T> {
    return await promise.catch((error) => {
        console.error("ERROR!", error, typeof error);
        if (typeof error == "object" && "status" in error) {
            // This is a request!
            error = error as Response;
            if (error.status === 404) {
                throw new ResponseError(ResponseErrorType.NOT_FOUND);
            }
        }
        if (
            error.message
                .toLowerCase()
                .includes(ResponseErrorType.TOO_MANY_REQUESTS.toLowerCase())
        ) {
            throw new ResponseError(ResponseErrorType.TOO_MANY_REQUESTS);
        }

        throw new ResponseError(ResponseErrorType.UNHANDLED, error.toString());;
    });
}