import {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useState
} from "react";

export interface ContextDataType {
    userAddress?: string
}

export const DataContextProviderContext = createContext<ContextDataType>({} as ContextDataType);

export const useContextData = () => useContext(DataContextProviderContext);

const DataContextProvider = ({ children }: { children: ReactNode }): JSX.Element => {
    return <DataContextProviderContext.Provider value={{ userAddress: '123' }}>
        {children}
    </DataContextProviderContext.Provider>
}

export default DataContextProvider;
