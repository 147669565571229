import { Types } from '@anyvm/moveup-sdk';
import { moveupCoinType } from '../constants/Constants';
import BigNumber from 'bignumber.js';

export const MOVEUP_DECIMALS = 18;

// block_metadata_transaction
// state_checkpoint_transaction

export function getTransactionSender(tx: Types.Transaction): string {
    let sender: string = '';
    if (tx.type === "user_transaction") {
        sender = (tx as Types.UserTransaction).sender;
    } else if (tx.type === "block_metadata_transaction") {
        sender = (tx as Types.BlockMetadataTransaction).proposer;
    }
    return sender;
}

export type TransactionCounterparty = {
    address: string;
    role: "receiver" | "smartContract";
};

export function getTransactionCounterparty(
    transaction: Types.Transaction
): TransactionCounterparty | undefined {
    if (transaction.type !== "user_transaction") {
        return undefined;
    }

    if (!("payload" in transaction)) {
        return undefined;
    }

    if (transaction.payload.type !== "entry_function_payload") {
        return undefined;
    }
    return getTransactionCounterpartyByPayload(transaction.payload);
}

export function getTransactionCounterpartyByPayload(
    payloadObject: Object
): TransactionCounterparty | undefined {

    // there are two scenarios that this transaction is an APT coin transfer:
    // 1. coins are transferred from account1 to account2:
    //    payload function is "0x1::coin::transfer" or "0x1::aptos_account::transfer_coins" and the first item in type_arguments is "0x1::eth::ETH"
    // 2. coins are transferred from account1 to account2, and account2 is created upon transaction:
    //    payload function is "0x1::aptos_account::transfer" or "0x1::aptos_account::transfer_coins"
    // In both scenarios, the first item in arguments is the receiver's address, and the second item is the amount.

    const payload = payloadObject as Types.TransactionPayload_EntryFunctionPayload;
    const typeArgument =
        payload.type_arguments.length > 0 ? payload.type_arguments[0] : undefined;
    const isAptCoinTransfer =
        (payload.function === "0x1::coin::transfer" ||
            payload.function === "0x1::moveup_account::transfer_coins") &&
        typeArgument === moveupCoinType;
    const isAptCoinInitialTransfer =
        payload.function === "0x1::moveup_account::transfer" ||
        payload.function === "0x1::moveup_account::transfer_coins";

    if (
        (isAptCoinTransfer || isAptCoinInitialTransfer) &&
        payload.arguments.length === 2
    ) {
        return {
            address: payload.arguments[0],
            role: "receiver",
        };
    } else {
        const smartContractAddr = payload.function.split("::")[0];
        return {
            address: smartContractAddr,
            role: "smartContract",
        };
    }
}

export function getTransactionFunction(transaction: Types.Transaction): string {

    const functionFullStr = getFunctionFullStr(transaction);

    if (functionFullStr === '') {
        return ''
    }

    return getFunctionByStr(functionFullStr);
}

function getFunctionFullStr(transaction: Types.Transaction) {
    if (!("payload" in transaction)) {
        return '';
    }

    if (transaction.payload.type === "script_payload") {
        return '';
    }

    if (!("function" in transaction.payload)) {
        return '';
    }

    return transaction.payload.function;
}

export function getFunctionByStr(funcStr: string): string {
    const [address, moduleName, functionName] = funcStr.split("::");

    if (
        funcStr === "0x1::coin::transfer" ||
        funcStr === "0x1::aptos_account::transfer"
    ) {
        return functionName;
    }

    return moduleName + "::" + functionName;
}

export function getTransactionFunctionRoute(transaction: Types.Transaction): string {

    const functionFullStr = getFunctionFullStr(transaction);

    if (functionFullStr === '') {
        return ''
    }

    const [address, moduleName, functionName] = functionFullStr.split("::");

    return `/address/${address}/modules/code/${moduleName}/${functionName}`;
}

type ChangeData = {
    coin: { value: string };
    deposit_events: {
        guid: {
            id: {
                addr: string;
                creation_num: string;
            };
        };
    };
    withdraw_events: {
        guid: {
            id: {
                addr: string;
                creation_num: string;
            };
        };
    };
};


function getMoveUpChangeData(change: Types.WriteSetChange): ChangeData | undefined {
    if (
        "address" in change &&
        "data" in change &&
        "type" in change.data &&
        change.data.type === `0x1::coin::CoinStore<${moveupCoinType}>` &&
        "data" in change.data
    ) {
        return JSON.parse(JSON.stringify(change.data.data)) as ChangeData;
    } else {
        return undefined;
    }
}

function isMoveUpEvent(event: Types.Event, transaction: Types.Transaction) {
    const changes: Types.WriteSetChange[] =
        "changes" in transaction ? transaction.changes : [];

    const aptEventChange = changes.filter((change) => {
        if ("address" in change && change.address === event.guid.account_address) {
            const data = getMoveUpChangeData(change);
            if (data !== undefined) {
                const eventCreationNum = event.guid.creation_number;
                let changeCreationNum;
                if (event.type === "0x1::coin::DepositEvent") {
                    changeCreationNum = data.deposit_events.guid.id.creation_num;
                } else if (event.type === "0x1::coin::WithdrawEvent") {
                    changeCreationNum = data.withdraw_events.guid.id.creation_num;
                }
                if (eventCreationNum === changeCreationNum) {
                    return change;
                }
            }
        }
        return false;
    });

    return aptEventChange.length > 0;
}

function getBalanceMap(transaction: Types.Transaction) {
    const events: Types.Event[] =
        "events" in transaction ? transaction.events : [];

    const accountToBalance = events.reduce(
        (
            balanceMap: {
                [key: string]: {
                    amountAfter: string;
                    amount: bigint;
                };
            },
            event: Types.Event,
        ) => {
            const addr = event.guid.account_address;

            if (
                event.type === "0x1::coin::DepositEvent" ||
                event.type === "0x1::coin::WithdrawEvent"
            ) {
                // deposit and withdraw events could be other coins
                // here we only care about APT events
                if (isMoveUpEvent(event, transaction)) {
                    if (!balanceMap[addr]) {
                        balanceMap[addr] = { amount: BigInt(0), amountAfter: "" };
                    }

                    const amount = BigInt(event.data.amount);

                    if (event.type === "0x1::coin::DepositEvent") {
                        balanceMap[addr].amount += amount;
                    } else {
                        balanceMap[addr].amount -= amount;
                    }
                }
            }

            return balanceMap;
        },
        {},
    );

    return accountToBalance;
}

export function getCoinBalanceChangeForAccount(
    transaction: Types.Transaction,
    address: string,
): bigint {
    const accountToBalance = getBalanceMap(transaction);

    if (!accountToBalance.hasOwnProperty(address)) {
        return BigInt(0);
    }

    const accountBalance = accountToBalance[address];
    return accountBalance.amount;
}

export function getTransactionAmount(
    transaction: Types.Transaction,
): bigint | undefined {
    if (transaction.type !== "user_transaction") {
        return undefined;
    }

    const accountToBalance = getBalanceMap(transaction);

    const [totalDepositAmount, totalWithdrawAmount] = Object.values(
        accountToBalance,
    ).reduce(
        ([totalDepositAmount, totalWithdrawAmount]: bigint[], value) => {
            if (value.amount > 0) {
                totalDepositAmount += value.amount;
            }
            if (value.amount < 0) {
                totalWithdrawAmount -= value.amount;
            }
            return [totalDepositAmount, totalWithdrawAmount];
        },
        [BigInt(0), BigInt(0)],
    );

    return totalDepositAmount > totalWithdrawAmount
        ? totalDepositAmount
        : totalWithdrawAmount;
}

export function getTransactionAmountAll({
    transaction,
    address,
}: {
    transaction: Types.Transaction;
    address?: string;
}): string | null {
    const isAccountTransactionTable = typeof address === "string";

    if (isAccountTransactionTable) {
        const amount = getCoinBalanceChangeForAccount(transaction, address);
        if (amount !== undefined) {
            const res = new BigNumber(amount.toString()).shiftedBy(-MOVEUP_DECIMALS).toString(10);
            return `${res} ETH`;
        }
    } else {
        const amount = getTransactionAmount(transaction);
        if (amount !== undefined) {
            const res = new BigNumber(amount.toString()).shiftedBy(-MOVEUP_DECIMALS).toString(10);
            return `${res} ETH`;
        }
    }

    return null;
}

function trimRight(rightSide: string) {
    while (rightSide.endsWith("0")) {
        rightSide = rightSide.slice(0, -1);
    }
    return rightSide;
}

export function getFormattedBalanceStr(
    balance: string,
    decimals?: number,
    fixedDecimalPlaces?: number,
): string {
    // If balance is zero or decimals is 0, just return it
    if (balance === "0" || (decimals !== undefined && decimals === 0)) {
        return balance;
    }

    const len = balance.length;
    decimals = decimals || MOVEUP_DECIMALS;

    // If length is less than decimals, pad with 0s to decimals length and return
    if (len <= decimals) {
        return "0." + (trimRight("0".repeat(decimals - len) + balance) || "0");
    }

    // Otherwise, insert decimal point at len - decimals
    const leftSide = BigInt(balance.slice(0, len - decimals)).toLocaleString(
        "en-US",
    );
    let rightSide = balance.slice(len - decimals);
    if (BigInt(rightSide) === BigInt(0)) {
        return leftSide;
    }

    // remove trailing 0s
    rightSide = trimRight(rightSide);
    if (
        fixedDecimalPlaces !== undefined &&
        rightSide.length > fixedDecimalPlaces
    ) {
        rightSide = rightSide.slice(0, fixedDecimalPlaces - rightSide.length);
    }

    if (rightSide.length === 0 || rightSide === "0") {
        return leftSide;
    }

    return leftSide + "." + trimRight(rightSide);
}

export function getTransactionGas(transaction: Types.Transaction, showGasUsed?: boolean): string {
    const hasGas = "gas_used" in transaction && "gas_unit_price" in transaction;
    if (!hasGas) return '';
    const gasUsed = transaction.gas_used;
    const gasUnitPrice = transaction.gas_unit_price;

    const amountStr = (BigInt(gasUnitPrice) * BigInt(gasUsed)).toString();
    let amount = amountStr;
    if (amountStr.startsWith("-")) {
        amount = amountStr.substring(1);
    }
    const formatted = getFormattedBalanceStr(amount);
    let result = `Gas ${formatted} ETH`
    if (showGasUsed) {
        result = `${result} (${gasUsed})`
    }
    return result;
}

export function getGas(gasUnitPrice: string, gasUsed: string, showGasUsed?: boolean): string {
    const amountStr = (BigInt(gasUnitPrice) * BigInt(gasUsed)).toString();
    let amount = amountStr;
    if (amountStr.startsWith("-")) {
        amount = amountStr.substring(1);
    }
    const formatted = getFormattedBalanceStr(amount);
    let result = `Gas ${formatted} ETH`
    if (showGasUsed) {
        result = `${result} (${gasUsed})`
    }
    return result;
}