import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Types } from '@anyvm/moveup-sdk';
import { CircularProgress, Collapse } from '@mui/material';
import { useAppContext } from '../../../context/AppContext';
import { useSnackbar } from '../../../context/SnackbarContext';
import ReactJson from 'react-json-view';
import { useJsonViewCardTheme } from './TxOverviewTab';
import { NoDataFound } from '../../../component/NoDataFound';
type TxChangeTabProps = {
    transaction: Types.Transaction;
};
export function TxPayloadTab({ transaction }: TxChangeTabProps) {
    const params = useParams();
    const appContext = useAppContext();
    const showSnackbar = useSnackbar();
    const [open, setOpen] = React.useState<boolean>(true);
    const jsonViewCardTheme = useJsonViewCardTheme();

    const { payload } = transaction as Types.UserTransaction;

    const renderPayload = useMemo(() => {
        if (!payload) {
            return <NoDataFound />
        }
        return <div>
            <div className='flex flex-row h-15 items-center panel-collapse-title px-5 -mx-5 text-sm text-item-head text-grey-600 cursor select-none border-b border-solid border-gray-200'
                onClick={() => setOpen(!open)}>
                <div className='tx-detail-item-left-container'>Type</div>
                <div className='flex-2'>script_payload</div>
                <img className={`ml-0.5 arrow-down ${!open && 'not-opened'}`} src="/images/arrow-down.svg" width={18} height={18} />
            </div>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <div className='flex flex-row h-auto items-center text-sm text-grey-600'>
                    <div className='flex-2 flex flex-row text-black overflow-hidden'>
                        <div style={{ backgroundColor: '#EDEDF7' }} className='rounded py-1 my-3 overflow-auto w-full'>
                            <ReactJson
                                src={payload as any}
                                theme={jsonViewCardTheme}
                                name={null}
                                displayObjectSize={false}
                                displayDataTypes={false}
                                quotesOnKeys={false}
                                style={{
                                    fontSize: '14px',
                                    color: '#66568A'
                                }}
                                collapsed={false}
                            />
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>
    }, [payload, open])
    return (
        <div className='pt-5'>
            {renderPayload}
        </div>
    );
}
