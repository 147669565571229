import { FC, ReactNode, createContext, useCallback, useContext, useEffect, useState } from "react";
import { AccountInfo, NetworkInfo, Wallet, WalletContextState, WalletInfo } from '../model/WalletContextState';
import { LOCAL_STORAGE_KEY_ACCOUNT, LOCAL_STORAGE_KEY_WALLET } from "../constants/Constants";

const WalletContext = createContext<WalletContextState>({} as WalletContextState);

export const useWallet = () => useContext(WalletContext);

export const WalletContextProvider: FC<{ children: ReactNode, plugins: ReadonlyArray<Wallet> }> = ({ children, plugins }) => {
    const [connected, setConnected] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [account, setAccount] = useState<AccountInfo | null>(null);
    const [network, setNetwork] = useState<NetworkInfo | null>(null);
    const [wallet, setWallet] = useState<WalletInfo | null>(null);
    const [wallets, setWallets] = useState<ReadonlyArray<Wallet>>(plugins);

    const connect = useCallback((wallet: Wallet, address: string) => {
        const account = { address: address };
        const walletInfo = { name: wallet.name, icon: wallet.icon, url: wallet.url };
        setAccount(account);
        setWallet(walletInfo);
        localStorage.setItem(LOCAL_STORAGE_KEY_ACCOUNT, JSON.stringify(account));
        localStorage.setItem(LOCAL_STORAGE_KEY_WALLET, JSON.stringify(walletInfo));
        setConnected(true);
    }, []);

    const disconnect = useCallback(() => {
        setAccount(null);
        setWallet(null);
        setConnected(false);
        localStorage.setItem(LOCAL_STORAGE_KEY_ACCOUNT, '');
        localStorage.setItem(LOCAL_STORAGE_KEY_WALLET, '');
    }, []);

    const handleReadyStateChange = (wallet: Wallet) => {
        setWallets((wallets) => [...wallets]);
    };

    useEffect(() => {
        const account = localStorage.getItem(LOCAL_STORAGE_KEY_ACCOUNT);
        if (account) {
            const accountInfo: AccountInfo = JSON.parse(account);
            setAccount(accountInfo);
            setConnected(true);
        }
    }, []);

    useEffect(() => {
        const wallet = localStorage.getItem(LOCAL_STORAGE_KEY_WALLET);
        if (wallet) {
            const walletInfo: WalletInfo = JSON.parse(wallet);
            setWallet(walletInfo);
        }
    }, []);

    useEffect(() => {
        // walletCore.on("connect", handleConnect);
        // walletCore.on("disconnect", handleDisconnect);
        // walletCore.on("accountChange", handleAccountChange);
        // walletCore.on("networkChange", handleNetworkChange);
        // walletCore.on("readyStateChange", handleReadyStateChange);
        // return () => {
        //     walletCore.off("connect", handleConnect);
        //     walletCore.off("disconnect", handleDisconnect);
        //     walletCore.off("accountChange", handleAccountChange);
        //     walletCore.off("networkChange", handleNetworkChange);
        //     walletCore.off("readyStateChange", handleReadyStateChange);
        // };
    }, [connected]);

    return (
        <WalletContext.Provider value={{
            connected: connected,
            isLoading: isLoading,
            account: account,
            network: network,
            connect: connect,
            disconnect: disconnect,
            wallet: wallet,
            wallets: wallets,
        }}>
            {children}
        </WalletContext.Provider>
    );
};
