import { Accordion, AccordionDetails, styled } from "@mui/material";

export const AppAccordion = styled(Accordion)({
    '&.MuiAccordion-rounded': {
        borderRadius: 0,
        boxShadow: 'none',
        '& .MuiAccordionSummary-root': {
            backgroundColor: '#FAFBFC',
            marginTop: '8px',
            color: '#80899A',
            fontSize: '14px',
        },
    }
});

export const AppAccordionDetails = styled(AccordionDetails)({
    maxHeight: '384px',
    overflowY: 'auto',
    margin: '24px',
    backgroundColor: '#EDEDF6',
    borderRadius: '8px',
    color: '#66568A',
    fontSize: '14px',
    padding: '8px 16px'
});
