import axios, { AxiosRequestConfig } from 'axios';

let baseURL = '/scan/api';

const timeout = 60000;

const apiInstance = axios.create({
	timeout,
	baseURL,
	withCredentials: false
});

const requestHandler = <T>(method: 'get' | 'post' | 'put' | 'delete', url: string, params: object = {}, config: AxiosRequestConfig = {}): Promise<T> => {
	let response: Promise<any>;
	switch (method) {
		case 'get':
			response = apiInstance.get(url, { params: { ...params }, ...config });
			break;
		case 'post':
			response = apiInstance.post(url, { ...params }, { ...config });
			break;
		case 'put':
			response = apiInstance.put(url, { ...params }, { ...config });
			break;
		case 'delete':
			response = apiInstance.delete(url, { params: { ...params }, ...config });
			break;
	}

	return new Promise<T>((resolve, reject) => {
		response.then(res => {
			const data = res.data;
            resolve(data);
		}).catch(error => {
			reject(error);
		})
	})
}

const apiService = {
	get: <T>(url: string, params?: object, config?: AxiosRequestConfig) => requestHandler<T>('get', url, params, config),
	post: <T>(url: string, params?: object, config?: AxiosRequestConfig) => requestHandler<T>('post', url, params, config),
	put: <T>(url: string, params?: object, config?: AxiosRequestConfig) => requestHandler<T>('put', url, params, config),
	delete: <T>(url: string, params?: object, config?: AxiosRequestConfig) => requestHandler<T>('delete', url, params, config)
};

export { apiService };