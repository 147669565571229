import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import { Types } from '@anyvm/moveup-sdk';
import { CircularProgress, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useAppContext } from '../../../context/AppContext';
import { useSnackbar } from '../../../context/SnackbarContext';
import { BalanceChange, getCoinBalanceChanges, getGas } from '../utils';
import { shortAddress } from '../../../utils/StringHelper';
import { CopyIcon } from '../../../component/CopyIcon';
import { MOVEUP_DECIMALS } from '../../../utils/TransactionsUtils';
import { NoDataFound } from '../../../component/NoDataFound';

type BalanceChangeTabProps = {
    transaction: Types.Transaction;
};
type BalanceChangeCellProps = {
    balanceChange: BalanceChange;
    transaction: Types.UserTransaction;
};

export function TxBalanceChangeTab({ transaction }: BalanceChangeTabProps) {
    const params = useParams();
    const appContext = useAppContext();
    const showSnackbar = useSnackbar();

    const balanceChanges = getCoinBalanceChanges(transaction);
    console.log('balanceChanges:', balanceChanges)

    useEffect(() => {
    }, [])

    if (balanceChanges.length === 0) {
        return <NoDataFound />;
    }

    const DEFAULT_COLUMNS: any[] = [
        { content: "account", },
        { content: "Balance Before", },
        { content: "gas", },
        { content: "change", },
        { content: "Balance After", justify: 'flex-end' },
    ];

    function getIsSender(
        address: string,
        transaction: Types.UserTransaction,
    ): boolean {
        return transaction.sender === address;
    }
    function GasCell({ balanceChange, transaction }: BalanceChangeCellProps) {
        const isSender = getIsSender(balanceChange.address, transaction);

        if (!isSender) {
            return <div></div>;
        }

        const gas = new BigNumber(getGas(transaction).toString()).shiftedBy(-MOVEUP_DECIMALS).toString(10)
        return (
            <div className='text-red-200'>{gas}{' ETH'}</div>
        );
    }
    return (
        <div className='-mx-5 pt-5 md:overflow-x-auto'>
            <Table>
                <TableHead>
                    <TableRow>
                        {DEFAULT_COLUMNS.map((column) => (
                            <TableCell key={column.content}>
                                <Stack
                                    className='text-grey-600 text-sm'
                                    direction="row"
                                    alignItems="center"
                                    justifyContent={column.justify || 'flex-start'}>{(column.content as string).toUpperCase()}</Stack>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                {balanceChanges.map((balanceChange: BalanceChange, i: number) => {
                    const balanceAfter = new BigNumber(balanceChange.amountAfter).shiftedBy(-MOVEUP_DECIMALS)
                    const balanceModification = new BigNumber(balanceChange.amount.toString()).shiftedBy(-MOVEUP_DECIMALS)
                    const isSender = getIsSender(balanceChange.address, transaction as Types.UserTransaction);
                    const gas = new BigNumber(getGas(transaction as Types.UserTransaction).toString()).shiftedBy(-MOVEUP_DECIMALS)
                    const balanceBefore = isSender ?
                        balanceAfter.minus(balanceModification).plus(gas)
                        : balanceAfter.minus(balanceModification)
                    return (
                        <TableBody key={balanceChange.address} className='h-18'>
                            <TableCell>
                                <div className='flex flex-row items-center'>
                                    <Link className='text-indigo-900' to={`/address/${balanceChange.address}/transactions`} >{shortAddress(balanceChange.address)}</Link>
                                    <div className='inline-block shrink-0'><CopyIcon text={balanceChange.address} /></div>
                                </div>
                            </TableCell>
                            <TableCell>{balanceBefore.toString(10).toLocaleString()}{' ETH'}</TableCell>
                            <TableCell>{GasCell({ balanceChange, transaction: transaction as Types.UserTransaction })}</TableCell>
                            <TableCell>
                                <div className={`${balanceModification.lt(0) ? 'text-red-200' : 'text-green-500'}`}>{balanceModification.toString(10).toLocaleString()}{' ETH'}</div>
                            </TableCell>
                            <TableCell>
                                <Stack
                                    className='text-sm'
                                    direction="row"
                                    alignItems="center"
                                    justifyContent={'flex-end'}>{balanceAfter.toString(10).toLocaleString()}{' ETH'}</Stack>
                            </TableCell>
                        </TableBody>
                    );
                })}
            </Table>
        </div>
    );
}
