import { Types } from '@anyvm/moveup-sdk';
import { CircularProgress } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PaginationRounded } from '../component/PaginationRounded';
import { TransactionsItem, TransactionsTitle } from '../component/Transactions';
import { useAppContext } from '../context/AppContext';
import { convertMoveUpAddressToEthMode, shortAddress } from '../utils/StringHelper';
import { timeFromNow } from '../utils/TimeHelper';
import { getTransactionAmountAll, getTransactionCounterparty, getTransactionFunction, getTransactionFunctionRoute, getTransactionGas, getTransactionSender } from '../utils/TransactionsUtils';
import { apiService } from '../utils/httpHelper';
import { APIResData } from '../utils/types';

export type CustomTx = {
    version: string
}

const pageCount = 10;
export function TransactionsPage() {
    const appContext = useAppContext();
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [txs, setTxsList] = useState<Types.Transaction[]>([]);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [queryParams, setQueryParams] = useState<{ [key: string]: any }>({ page: 1 });
    const [secondValue, setSecondValue] = useState<number>(new Date().getTime() / 1000)

    const [queryFrom, setQueryFrom] = useState<number | undefined>(undefined);
    const [queryTotal, setQueryTotal] = useState<number | undefined>(undefined);

    useEffect(() => {
        const intervalHandler = setInterval(() => {
            setSecondValue(new Date().getTime() / 1000)
        }, 1000)
        return () => {
            clearInterval(intervalHandler)
        }
    }, [])

    const loadUserTxData = useCallback(async (skip: number) => {
        try {
            setShowLoading(true)
            const queryData: any = { skip: skip };
            if (queryFrom) {
                queryData.from = Number(queryFrom);
            }
            if (queryTotal) {
                queryData.total = Number(queryTotal);
            }
            const { data, from, total } = await apiService.get<APIResData<CustomTx>>('/tx/user', queryData)

            if (from) {
                if (Number(from) !== Number(queryFrom)) {
                    setQueryFrom(Number(from))
                    if (total) {
                        if (Number(total) !== Number(queryTotal)) {
                            setQueryTotal(Number(total))
                        }
                    }
                    return
                }
            }
            const reqAll: Promise<Types.Transaction>[] = [];
            data.forEach(ver => {
                reqAll.push(appContext.provider.getTransactionByVersion(Number(ver.version)))
            })
            const txsAll = await Promise.all(reqAll)
            setTxsList(txsAll)

        } catch (e) {
            console.error('loadUserTxData error: ', e);
        } finally {
            setShowLoading(false)
        }
    }, [appContext.provider, queryFrom, queryTotal]);

    useEffect(() => {
        loadUserTxData((pageNumber - 1) * 10);
    }, [loadUserTxData, pageNumber]);

    const renderTxList = useMemo(() => {
        if (showLoading) return <div className='my-28 flex justify-center items-center'><CircularProgress /></div>
        return txs.map((tx: Types.Transaction, index: number) => {
            const version = 'version' in tx && tx.version;
            const sender = convertMoveUpAddressToEthMode(getTransactionSender(tx));
            const timeAgo = timeFromNow(`${'timestamp' in tx && tx.timestamp}`);
            const senderShort = shortAddress(sender);
            const sendTo = convertMoveUpAddressToEthMode(getTransactionCounterparty(tx)?.address ?? '');
            const sendToShort = shortAddress(sendTo);
            const func = getTransactionFunction(tx);
            const funcRoute = getTransactionFunctionRoute(tx);
            const amount = getTransactionAmountAll({ transaction: tx });
            const gas = getTransactionGas(tx);

            return (
                <div key={`block-info-page-tx-${index}`}>
                    <TransactionsItem
                        version={`${version}`}
                        timeAgo={timeAgo}
                        sender={sender}
                        senderShort={senderShort}
                        sendTo={sendTo}
                        sendToShort={sendToShort}
                        funcStr={func}
                        funcRoute={funcRoute}
                        amount={amount ?? ''}
                        gas={gas}
                    />
                </div>
            )
        });
    }, [showLoading, txs])

    return (
        <div>
            <div className='mt-10 mb-8 pt-8 h-auto w-full flex justify-start text-h1'>User Transactions</div>
            <div className='w-full bg-white rounded-2xl shadow-md mb-12 overflow-hidden md:overflow-x-auto px-5'>
                <TransactionsTitle />
                {renderTxList}
            </div>
            <div className='h-7' />
            <PaginationRounded totalCount={100} onChange={setPageNumber} />
        </div>
    );
}
