import { CoinClient, CustomEndpoints, FaucetClient, Network, Provider } from '@anyvm/moveup-sdk';
import { FC, ReactNode, createContext, useContext } from "react";
import { AppModel } from "../model/AppModel";
import { WalletContextProvider } from './WalletContext';
import { MetamaskWallet } from '../adapter/MetamaskWallet';
import { MOVE_UP_FAUCET_URL, MOVE_UP_NODE_URL, NetworkToNodeAPI } from "../constants/Constants";
import { CloverWallet } from '../adapter/CloverWallet';

const AppContext = createContext<AppModel>({} as AppModel);

export const useAppContext = () => useContext(AppContext);

export const AppContextProvider: FC<{ children: ReactNode }> = ({ children }) => {

    const network = Network.MAINNET;
    const customEndpoints = {
        fullnodeUrl: NetworkToNodeAPI[Network.MAINNET],
        indexerUrl: NetworkToNodeAPI[Network.MAINNET],
    } as CustomEndpoints;
    const provider = new Provider(customEndpoints);
    const faucetClient = new FaucetClient(MOVE_UP_NODE_URL, MOVE_UP_FAUCET_URL);
    const coinClient = new CoinClient(provider.moveupClient);

    const appModel = {
        network: network,
        provider: provider,
        faucetClient: faucetClient,
        coinClient: coinClient,
    } as AppModel;

    return (
        <AppContext.Provider value={appModel}>
            <WalletContextProvider plugins={[
                new MetamaskWallet(),
                new CloverWallet(),
            ]}>
                {children}
            </WalletContextProvider>
        </AppContext.Provider>
    );
};
