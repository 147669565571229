import { Tooltip } from "@mui/material";

interface Params {
    tooltipTitle: string;
}

export function InfoIcon(params: Params) {

    return (
        <Tooltip title={params.tooltipTitle} placement="top">
            <img className='ml-1.5 cursor-pointer' src='/images/icon-info.svg' width={16} alt="" />
        </Tooltip>
    )
}