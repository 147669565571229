import { SvgIcon, SvgIconProps, Tooltip } from "@mui/material";
import { useState } from "react";

export function CopySvg(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 16 16" {...props}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6 8.66667V8C6 6.89733 6.89733 6 8 6H8.66667V3.778C8.66667 3.53267 8.46733 3.33333 8.222 3.33333H3.778C3.53267 3.33333 3.33333 3.53267 3.33333 3.778V8.222C3.33333 8.46733 3.53267 8.66667 3.778 8.66667H6ZM6 10H3.778C2.79733 10 2 9.20267 2 8.222V3.778C2 2.79733 2.79733 2 3.778 2H8.222C9.20267 2 10 2.79733 10 3.778V6H12C13.1027 6 14 6.89733 14 8V12C14 13.1027 13.1027 14 12 14H8C6.89733 14 6 13.1027 6 12V10ZM7.33333 8C7.33333 7.63267 7.63267 7.33333 8 7.33333H12C12.368 7.33333 12.6667 7.63267 12.6667 8V12C12.6667 12.3673 12.368 12.6667 12 12.6667H8C7.63267 12.6667 7.33333 12.3673 7.33333 12V8Z" fill="#2E2E2E" />
            <mask id="mask0_9807_1495" maskUnits="userSpaceOnUse" x="2" y="2" width="12" height="12">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6 8.66667V8C6 6.89733 6.89733 6 8 6H8.66667V3.778C8.66667 3.53267 8.46733 3.33333 8.222 3.33333H3.778C3.53267 3.33333 3.33333 3.53267 3.33333 3.778V8.222C3.33333 8.46733 3.53267 8.66667 3.778 8.66667H6ZM6 10H3.778C2.79733 10 2 9.20267 2 8.222V3.778C2 2.79733 2.79733 2 3.778 2H8.222C9.20267 2 10 2.79733 10 3.778V6H12C13.1027 6 14 6.89733 14 8V12C14 13.1027 13.1027 14 12 14H8C6.89733 14 6 13.1027 6 12V10ZM7.33333 8C7.33333 7.63267 7.63267 7.33333 8 7.33333H12C12.368 7.33333 12.6667 7.63267 12.6667 8V12C12.6667 12.3673 12.368 12.6667 12 12.6667H8C7.63267 12.6667 7.33333 12.3673 7.33333 12V8Z" fill="white" />
            </mask>
            <g mask="url(#mask0_9807_1495)">
                <rect width="16" height="16" fill={props.color} />
            </g>
        </SvgIcon>
    );
}

interface Params {
    text: string;
    color?: string;
}

export function CopyIcon(params: Params) {
    const [copied, setCopied] = useState(false);

    const color = params.color ?? '#80899A';

    function onCopy(event: React.MouseEvent<any>) {
        event.preventDefault();
        const text = params.text ?? '';
        navigator.clipboard.writeText(text);

        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    }

    return (
        <div className="ml-1.5 cursor-pointer inline-flex items-center align-middle">
            {
                !copied && <Tooltip title={'Copy to clipboard'} placement="top">
                    <CopySvg style={{ width: 16, color: color }} onClick={onCopy} />
                </Tooltip>
            }
            {
                copied && <Tooltip title={'Copied!'} placement="top">
                    <img src='/images/icon-right-green.svg' width={14} alt="" onClick={onCopy} />
                </Tooltip>
            }
        </div>
    )
}