import { Tooltip } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useWallet } from "../context/WalletContext";
import { useGetAccountMoveUpBalance } from "../hooks/useGetAccountMoveupBalance";
import { shortAddress } from "../utils/StringHelper";
import { AppDialog } from "./AppDialog";
import { useGetAccountEthBalance } from "../hooks/useGetAccountEthBalance";
import { CloseButton } from "./CloseButton";

type AccountDialogProps = {
    handleClose: () => void;
    modalOpen: boolean;
};

function AssetsItem({ name, count }: { name: string, count?: string | null }) {
    return (
        <div className="flex py-3">
            <div className="text-sm text-gray-600">{name}</div>
            <div className="flex-1" />
            <div className="text-sm text-gray-700">{count ?? 0}</div>
        </div>
    );
}

export function AccountDialog({ handleClose, modalOpen }: AccountDialogProps) {
    const navigate = useNavigate();

    const { account, disconnect, wallet } = useWallet();

    const [copied, setCopied] = useState(false);
    const ethBalance = useGetAccountEthBalance(account?.address ?? '')
    const mepBalance = useGetAccountMoveUpBalance(account?.address ?? '');

    const onCopy = (event: React.MouseEvent<any>) => {
        event.preventDefault();
        const text = account?.address ?? '';
        navigator.clipboard.writeText(text);

        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    }

    const gotoAddressPage = () => {
        handleClose();
        navigate(`/address/${account?.address}/transactions`);
    }

    return (
        <AppDialog onClose={handleClose} open={modalOpen} style={{ borderRadius: 16 }}>
            <div className="w-96 p-6">
                <div className="flex">
                    <img className='w-6 h-6' src={wallet?.icon} alt='' />
                    <div className="w-2" />
                    <div className="text-base text-gray-700 font-semibold">{shortAddress(wallet?.name)}</div>
                    <div className="flex-1" />
                    <CloseButton width={24} onClose={handleClose}/>
                </div>
                <div className="h-4" />
                <div className="flex">
                    <div onClick={onCopy} className="cursor-pointer flex text-sm font-semibold text-purple-500">
                        {
                            !copied && <Tooltip title={'Copy to clipboard'} placement="top">
                                <img src="/images/icon-copy-purple.svg" alt='' />
                            </Tooltip>
                        }
                        {
                            copied && <Tooltip title={'Copied!'} placement="top">
                                <img className='w-3' src='/images/icon-right-green.svg' alt="" />
                            </Tooltip>
                        }
                        <div className="w-1" />
                        <div>Copy Address</div>
                    </div>
                    <div className="w-4" />
                    <div onClick={gotoAddressPage} className="cursor-pointer flex text-sm font-semibold text-purple-500">
                        <img src="/images/icon-share.svg" alt='' />
                        <div className="w-1" />
                        <div>View on Explorer</div>
                    </div>
                </div>
                <div className="h-10" />
                <AssetsItem name='ETH' count={ethBalance} />
                <div className='w-full h-px bg-gray-200' />
                <AssetsItem name='MEP' count={mepBalance} />
                <div className="h-8" />
                <div
                    onClick={disconnect}
                    className="w-full bg-gray-100 w-28 h-11 hover:bg-gray-200 rounded-lg cursor-pointer flex items-center justify-center text-xs font-semibold"
                >
                    Disconnect
                </div>
            </div>
        </AppDialog>
    );
}