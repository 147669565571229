import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppContext } from "../context/AppContext";
import { CircularProgress, Tooltip } from "@mui/material";
import { shortAddress } from "../utils/StringHelper";
import { CopyIcon } from "../component/CopyIcon";
import { timeFromNow } from "../utils/TimeHelper";
import { Types } from '@anyvm/moveup-sdk';
import { PaginationRounded } from "../component/PaginationRounded";

const pageCount = 10;
export function BlocksPage() {
    const appContext = useAppContext();

    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [latestHeight, setLatestHeight] = useState<number>(0);
    const [blocks, setBlocks] = useState<Types.Block[]>([]);
    const [secondValue, setSecondValue] = useState<number>(new Date().getTime() / 1000)
    useEffect(() => {
        const intervalHandler = setInterval(() => {
            setSecondValue(new Date().getTime() / 1000)
        }, 1000)
        return () => {
            clearInterval(intervalHandler)
        }
    }, [])
    const onPageChange = useCallback(async (page: number) => {
        try {
            setShowLoading(true);
            const promises = [];
            let currentVersion = latestHeight - (page - 1) * pageCount;
            while (currentVersion > 0 && (currentVersion > (latestHeight - page * pageCount))) {
                promises.push(appContext.provider.getBlockByHeight(currentVersion));
                currentVersion--;
            }
            const blocks = await Promise.all(promises);
            setBlocks(blocks);
        } catch (error) {
            console.error('An error occurred:', error);
        } finally {
            setShowLoading(false);
        }
    }, [appContext.provider, latestHeight])

    useEffect(() => {
        const initLedger = async () => {
            try {
                setShowLoading(true);
                const ledger: Types.IndexResponse = await appContext.provider.getLedgerInfo();
                const blockHeight = Number(ledger.block_height);
                setLatestHeight(blockHeight);
            } catch (e) {
                console.error('initLedger error:', e);
            } finally {
                setShowLoading(false);
            }
        }
        initLedger();
    }, [appContext.provider]);

    return (
        <div className='w-full h-full flex flex-col items-center min-h-screen'>
            <div className='w-full max-w-7xl h-full overflow-y-auto flex flex-col'>
                <div className='mt-16 mb-8 text-left text-4xl font-extrabold'>Latest Blocks</div>
                <div className='w-full bg-white rounded-2xl shadow-md overflow-hidden md:overflow-x-auto'>
                    <div className='h-16 flex flex-row items-center pl-5'>
                        <div className='font-semibold flex-1 text-gray-600 md:w-28 md:flex-none'>BLOCK</div>
                        <div className='font-semibold flex-1 text-gray-600 md:w-36 md:flex-none'>AGE</div>
                        <div className='font-semibold flex-1 text-gray-600 md:w-52 md:flex-none'>HASH</div>
                        <div className='font-semibold flex-1 text-gray-600 md:w-28 md:flex-none'>Txn</div>
                    </div>
                    {!showLoading && blocks.map((block: Types.Block, index: number) => {
                        const txCount = (Number(block.last_version) - Number(block.first_version)) + 1;

                        return <div className='h-16 flex flex-col' key={`block-list-page-block-${index}`}>
                            <div className='w-full h-px bg-gray-200 md:w-149' />
                            <Link
                                to={`/block/${block.block_height}/overview`}
                                className='flex-1 flex flex-row items-center pl-5 hover:bg-gray-100 cursor-pointer'
                            >
                                <div className='flex-1 font-semibold text-indigo-900 hash-text md:w-28 md:flex-none'>
                                    <Link to={`/block/${block.block_height}/overview`} className="hover:text-indigo-600">
                                        {block.block_height}
                                    </Link>
                                </div>
                                <div className='flex-1 font-semibold text-gray-600 md:w-36 md:flex-none'>{timeFromNow(block.block_timestamp)}</div>
                                <div className='flex-1 font-semibold text-indigo-900 cursor-pointer w-0 flex flex-row md:w-52 md:flex-none'>
                                    <Tooltip title={block.block_hash} placement="top">
                                        <div className="hover:text-indigo-600">{shortAddress(block.block_hash)}</div>
                                    </Tooltip>
                                    <CopyIcon text={block.block_hash} />
                                </div>
                                <div className='flex-1 font-semibold text-gray-600 md:w-28 md:flex-none'>{txCount >= 2 ? txCount - 2 : 0}</div>
                            </Link>
                        </div>
                    })}
                    {showLoading && <div className='my-28 flex justify-center items-center'><CircularProgress /></div>}
                </div>
                <div className='h-7' />
                {latestHeight !== 0 && <PaginationRounded totalCount={Math.ceil(latestHeight / pageCount)} onChange={onPageChange} />}
                <div className='h-32 md:h-10' />
            </div>
        </div>
    );
}
