import { Types } from "@anyvm/moveup-sdk";
import { CircularProgress, Menu, MenuItem } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../context/AppContext";
import { useSnackbar } from "../context/SnackbarContext";
import { ModuleModel, PackageModel } from "../model/PackageModel";
import { getBytecodeSizeInKB, getEntryFunctions } from "../utils/ModuleUtils";
import { AppAccordionDetails } from "./AppAccordion";
import { CodeSnippet } from "./CodeSnippet";
import { NoDataFound } from "./NoDataFound";

export function AddressModules(params: { packages: PackageModel[] }) {
    const { packages } = params;
    const showSnackbar = useSnackbar();
    const { addressId } = useParams();
    const appContext = useAppContext();
    const location = useLocation();

    const labelRef = useRef<HTMLAnchorElement | null>(null);
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [selectedModule, setSelectedModule] = useState<ModuleModel>();
    const [moduleInfo, setModuleInfo] = useState<Types.MoveModuleBytecode>();

    useEffect(() => {
        const loadModule = async () => {
            try {
                let moduleName = selectedModule?.name ?? '';
                setShowLoading(true);
                const module = await appContext.provider.getAccountModule(`${addressId}`, moduleName);
                setModuleInfo(module)
            } catch (e) {
                console.error('address page loadModule error: ', e);
            } finally {
                setShowLoading(false);
            }
        }
        loadModule();
    }, [addressId, appContext.provider, selectedModule?.name])

    const extractCode = (url: string): string | null => {
        const match = url.match(/code\/([^\\/]*)/);
        return match ? match[1] : null;
    }

    useEffect(() => {
        const moduleName = extractCode(location.pathname);
        for (const p of packages) {
            for (const m of p.modules) {
                if (m.name === moduleName) {
                    setSelectedModule(m);
                    return;
                }
            }
        }
        const defaultModule = packages[0]?.modules[0];
        if (defaultModule) {
            setSelectedModule(defaultModule);
        }
    }, [location.pathname, packages]);

    useEffect(() => {
        labelRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, [selectedModule]);

    if (packages.length === 0) {
        return <NoDataFound />
    }

    return <div className='flex flex-row md:flex-col'>
        {
            isMobile && <ModuleLabelMobile packages={packages} />
        }
        {
            !isMobile && <div className='w-56 flex flex-col bg-gray-100 max-h-screen overflow-y-scroll'>
                {
                    packages.map((p, pIndex) => (
                        <div key={`address-info-page-package-tab-${p.name}-${pIndex}`}>
                            <div className='h-14 bg-gray-100 flex items-center text-base font-bold w-56 pl-4 text-gray-700 rounded-t-lg'>
                                {p.name}
                            </div>
                            {
                                p.modules.map((m, mIndex) => (
                                    <Link
                                        ref={selectedModule === m ? labelRef : null}
                                        to={`code/${m.name}`}
                                        key={`address-info-page-module-tab-${m.name}-${mIndex}`}
                                        className={`${selectedModule?.name === m.name ? 'bg-gray-300 font-semibold' : ''} w-full py-3 pl-5 text-sm flex items-center cursor-pointer`}
                                    >
                                        {m.name}
                                    </Link>

                                ))
                            }
                        </div>
                    ))
                }
            </div>
        }
        <div className='flex-1 max-h-screen overflow-y-scroll'>
            {
                !showLoading && !moduleInfo && <NoDataFound />
            }
            {
                showLoading && <div className='my-28 flex justify-center items-center'><CircularProgress /></div>
            }
            {
                !showLoading && <div>
                    <ModuleTitle
                        name={selectedModule?.name ?? ''}
                        funcCount={getEntryFunctions(moduleInfo?.abi?.exposed_functions ?? [])}
                        byte={getBytecodeSizeInKB(moduleInfo?.bytecode ?? '')}
                    />
                    <div className='p-4'>
                        <CodeSnippet bytecode={selectedModule?.source ?? ''} />
                        <div className='w-full h-px bg-gray-200 my-4' />
                        <div className='flex flex-row'>
                            <div className='text-base font-bold flex flex-row'>ABI</div>
                            <div className='flex-1' />
                            <div
                                className='text-base font-bold cursor-pointer text-purple-500' onClick={() => {
                                    navigator.clipboard.writeText(JSON.stringify(moduleInfo?.abi, null, 2));
                                    showSnackbar('Copied');
                                }}>
                                Copy Code
                            </div>
                        </div>
                        <AppAccordionDetails>
                            <pre>{JSON.stringify(moduleInfo?.abi, null, 2)}</pre>
                        </AppAccordionDetails>
                    </div>
                </div>
            }
        </div>
    </div>
}

function ModuleLabelMobile(params: { packages: PackageModel[] }) {
    const navigate = useNavigate();
    const { packages } = params;
    const [selectedPackage, setSelectedPackage] = useState<PackageModel>(packages[0]);
    const [selectedModule, setSelectedModule] = useState<ModuleModel>(packages[0].modules[0]);
    const [anchorPackageEl, setAnchorPackageEl] = useState<null | HTMLElement>(null);
    const [anchorModuleEl, setAnchorModuleEl] = useState<null | HTMLElement>(null);
    const packageOpen = Boolean(anchorPackageEl);
    const moduleOpen = Boolean(anchorModuleEl);

    const onSelectPackage = (p: PackageModel) => {
        setAnchorPackageEl(null)
        setSelectedPackage(p);
        setSelectedModule(p.modules[0]);
        navigate(`code/${p.modules[0].name}`);
    };

    const onSelectModule = (m: ModuleModel) => {
        setAnchorModuleEl(null)
        setSelectedModule(m);
        navigate(`code/${m.name}`);
    };

    return (
        <div className="bg-grey-100 p-4">
            {
                packages.length === 1 && (
                    <div className='h-14 bg-gray-100 flex items-center text-base font-bold w-56 pl-4 text-gray-700 rounded-t-lg'>
                        {packages[0].name}
                    </div>
                )
            }
            {
                packages.length > 1 && (
                    <div className="w-full">
                        <div
                            className="h-11 pl-3 bg-grey-200 flex items-center rounded-lg font-bold text-gray-700 text-base"
                            onClick={(e) => setAnchorPackageEl(e.currentTarget)}
                        >
                            {selectedPackage.name}
                        </div>
                        <Menu
                            id="module-label-package-menu"
                            anchorEl={anchorPackageEl}
                            open={packageOpen}
                            onClose={() => setAnchorPackageEl(null)}
                            PaperProps={{
                                style: {
                                    borderRadius: '8px',
                                    width: '85%',
                                },
                            }}
                        >
                            {packages.map((p, index) => (
                                <MenuItem
                                    key={`module-label-package-menu-item-${p.name}-${index}`}
                                    onClick={() => onSelectPackage(p)}
                                >
                                    {p.name}
                                </MenuItem>
                            ))}
                        </Menu>
                    </div>
                )
            }
            <div className="h-1" />
            <div>
                <div
                    className="h-11 pl-8 bg-grey-200 flex items-center rounded-lg"
                    onClick={(e) => setAnchorModuleEl(e.currentTarget)}
                >
                    {selectedModule.name}
                </div>
                <Menu
                    id="module-label-module-menu"
                    anchorEl={anchorModuleEl}
                    open={moduleOpen}
                    onClose={() => setAnchorModuleEl(null)}
                    PaperProps={{
                        style: {
                            borderRadius: '8px',
                            width: '85%',
                        },
                    }}
                >
                    {selectedPackage.modules.map((m, index) => (
                        <MenuItem
                            key={`module-label-module-menu-item-${m.name}-${index}`}
                            onClick={() => onSelectModule(m)}
                        >
                            {m.name}
                        </MenuItem>
                    ))}
                </Menu>
            </div>
        </div>
    )
}

function ModuleTitle(params: { name: string, funcCount?: number, byte?: number }) {
    const { name, funcCount, byte } = params;
    return (
        <div className='w-full h-14 flex-1 bg-gray-150 text-sm px-6 flex items-center font-bold md:flex-col md:items-start md:justify-center'>
            <div>{name}</div>
            <div className='flex-1 md:flex-none' />
            <div className='text-gray-600'>
                {`${funcCount} entry functions | Bytecode: ${byte} KB`}
            </div>
        </div>
    );
}