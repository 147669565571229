import { Types } from "@anyvm/moveup-sdk";
import { UseQueryResult, useQuery } from "react-query";
import Web3 from "web3";
import { useAppContext } from "../context/AppContext";
import { ResponseError } from "../dev/client";

export function useGetAccountEthResources(
    address: string,
): UseQueryResult<string, ResponseError> {
    const appContext = useAppContext();

    const getAccountResources = async (address: string): Promise<string> => {
        const web3 = new Web3('https://mainnet.infura.io/v3/4b2ff511773842be8b403a72f6460f3d');
        const balance = await web3.eth.getBalance(address ?? '');
        return web3.utils.fromWei(balance, 'ether');
    }

    return useQuery<string, ResponseError>(
        ["accountResources", { address }, appContext.network],
        () => getAccountResources(address),
    );
}

export function useGetAccountEthBalance(address: Types.Address) {
    const { isLoading, data, error } = useGetAccountEthResources(address);

    if (isLoading || error || !data || typeof data !== "string") {
        return null;
    }

    return data;
}
