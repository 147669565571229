import { Types } from '@anyvm/moveup-sdk';
import { CircularProgress } from "@mui/material";
import moment from 'moment';
import { useCallback, useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import { BackButton } from "../component/BackButton";
import { CopyIcon } from "../component/CopyIcon";
import { InfoIcon } from "../component/InfoIcon";
import { NoDataFound } from "../component/NoDataFound";
import { AppTab, AppTabs, TabPanel } from "../component/TabPanel";
import { TransactionsItem, TransactionsTitle } from "../component/Transactions";
import { useAppContext } from "../context/AppContext";
import { capitalizeFirstLetter, convertMoveUpAddressToEthMode, shortAddress } from "../utils/StringHelper";
import { timeFromNow } from "../utils/TimeHelper";
import { getTransactionAmountAll, getTransactionCounterparty, getTransactionFunction, getTransactionFunctionRoute, getTransactionGas, getTransactionSender } from "../utils/TransactionsUtils";
import { PaginationRounded } from '../component/PaginationRounded';

export enum BlockInfoTabs {
    overview = 0,
    transactions = 1,
}

const pageCount = 10;

export function BlockInfoPage() {
    const { blockId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const appContext = useAppContext();

    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [showPagination, setShowPagination] = useState<boolean>(false);
    const [blockInfo, setBlockInfo] = useState<Types.Block | undefined>(undefined);
    const [currentTxs, setCurrentTxs] = useState<Types.Transaction[]>([]);
    const [currentTab, setCurrentTab] = useState<BlockInfoTabs>(BlockInfoTabs.overview);

    const onPageChange = useCallback(async (page: number) => {
        const allTxs = blockInfo?.transactions ?? [];
        const currentTxs = allTxs.slice((page - 1) * pageCount, page * pageCount);
        setCurrentTxs(currentTxs);
    }, [blockInfo?.transactions]);

    useEffect(() => {
        /*
         * Redirect to block/:blockId/overview
         */
        const currentTab = location.pathname.split('/').pop() ?? '';
        const isSupportTab = Object.values(BlockInfoTabs).includes(currentTab);
        if (isSupportTab) {
            setCurrentTab(BlockInfoTabs[currentTab as keyof typeof BlockInfoTabs]);
        } else {
            navigate(`${BlockInfoTabs[0]}`);
        }
    }, [location.pathname, navigate]);

    useEffect(() => {
        const loadBlockInfo = async () => {
            const blockHeight = Number(blockId ?? 0);
            setShowLoading(true);
            const blockInfo = await appContext.provider.getBlockByHeight(blockHeight, true);
            setShowLoading(false);
            setBlockInfo(blockInfo);
            const txs = blockInfo.transactions ?? [];
            const showPagination = txs.length > pageCount;
            setShowPagination(showPagination)
            if (!showPagination) {
                /// show first page
                const allTxs = blockInfo?.transactions ?? [];
                const currentTxs = allTxs.slice(0, pageCount);
                setCurrentTxs(currentTxs);
            }
        };
        loadBlockInfo();
    }, [appContext.provider, blockId]);

    return (
        <div className="flex flex-col min-h-screen">
            <div className="h-16" />
            <BackButton />
            <div className="h-7" />
            <div className="text-left text-4xl font-extrabold">Block</div>
            <div className="h-7" />
            <div className="w-full h-full bg-white rounded-2xl shadow-md overflow-hidden">
                {!showLoading && blockInfo && <div className="px-5">
                    <AppTabs value={currentTab} onChange={(_, newValue) => {
                        setCurrentTab(newValue);
                        navigate(`${BlockInfoTabs[newValue]}`);
                    }}>
                        <AppTab label={capitalizeFirstLetter(BlockInfoTabs[0])} />
                        <AppTab label={capitalizeFirstLetter(BlockInfoTabs[1])} />
                    </AppTabs>
                    <Routes>
                        <Route path={`/${BlockInfoTabs[0]}`} element={
                            <TabPanel value={currentTab} index={BlockInfoTabs.overview}><BlockOverviewPanel block={blockInfo} /></TabPanel>
                        } />
                        <Route path={`/${BlockInfoTabs[1]}`} element={
                            <TabPanel value={currentTab} index={BlockInfoTabs.transactions}><TransactionsPanel txs={currentTxs} /></TabPanel>
                        } />
                    </Routes>
                </div>}
                {showLoading && <div className="my-28 flex justify-center items-center"><CircularProgress /></div>}
            </div>
            <div className='h-7' />
            {currentTab === BlockInfoTabs.transactions && showPagination && <PaginationRounded totalCount={Math.ceil((blockInfo?.transactions?.length ?? 0) / pageCount)} onChange={onPageChange} />}
        </div>
    );
}

export function BlockOverviewPanel(params: { block: Types.Block }) {

    const blockInfo = params.block;
    const txnCount = (Number(blockInfo.last_version) - Number(blockInfo.first_version)) + 1;
    const timestamp = moment(Number(blockInfo.block_timestamp) / 1000).format('MM/DD/YYYY HH:mm:ss');

    return (
        <div>
            <div className="h-14 flex flex-row items-center">
                <div className="w-60 text-gray-600 text-sm font-semibold">Block Height</div>
                <div className="text-gray-700 text-sm font-semibold">{blockInfo.block_height}</div>
            </div>
            <div className="w-full h-px bg-gray-200" />
            <div className="h-14 flex flex-row items-center">
                <div className="w-60 text-gray-600 text-sm font-semibold">Txn</div>
                <div className="text-indigo-900 text-sm font-semibold">{txnCount >= 2 ? txnCount - 2 : 0}</div>
            </div>
            <div className="w-full h-px bg-gray-200" />
            <div className="h-14 flex flex-row items-center">
                <div className="w-60 text-gray-600 text-sm flex flex-row font-semibold">
                    Timestamp
                    <InfoIcon tooltipTitle='The machine timestamp of when the block is committed.' />
                </div>
                <div className="flex flex-row text-gray-700 text-sm font-semibold">
                    {timestamp}
                    <CopyIcon text={timestamp} />
                </div>
            </div>
            <div className="w-full h-px bg-gray-200" />
            <div className="h-14 flex flex-row items-center">
                <div className="w-60 text-gray-600 text-sm font-semibold">Sequencer</div>
                <div className="flex flex-row text-indigo-900 text-sm font-semibold">
                    {blockInfo.block_hash}
                    <CopyIcon text={blockInfo.block_hash} />
                </div>
            </div>
        </div>
    );
}

export function TransactionsPanel(params: { txs: Types.Transaction[] }) {

    const transactions = params.txs.filter((r) => r.type === 'user_transaction');

    if (transactions.length === 0) {
        return <NoDataFound />;
    }

    return (
        <div>
            <TransactionsTitle />
            {transactions && transactions.map((tx: Types.Transaction, index: number) => {
                const version = 'version' in tx && tx.version;
                const sender = convertMoveUpAddressToEthMode(getTransactionSender(tx));
                const timeAgo = timeFromNow(`${'timestamp' in tx && tx.timestamp}`);
                const senderShort = shortAddress(sender);
                const sendTo = convertMoveUpAddressToEthMode(getTransactionCounterparty(tx)?.address ?? '');
                const sendToShort = shortAddress(sendTo);
                const func = getTransactionFunction(tx);
                const funcRoute = getTransactionFunctionRoute(tx);
                const amount = getTransactionAmountAll({ transaction: tx });
                const gas = getTransactionGas(tx);

                return (
                    <div key={`block-info-page-tx-${index}`}>
                        <TransactionsItem
                            version={`${version}`}
                            timeAgo={timeAgo}
                            sender={sender}
                            senderShort={senderShort}
                            sendTo={sendTo}
                            sendToShort={sendToShort}
                            funcStr={func}
                            funcRoute={funcRoute}
                            amount={amount ?? ''}
                            gas={gas}
                        />
                    </div>
                )
            })}
        </div>
    );
}
