import React, { useEffect, useState } from 'react';
import { Pagination, PaginationRenderItemParams, Stack } from '@mui/material';

interface PaginationRoundedProps {
    totalCount: number,
    onChange: (page: number) => void,
}
export function PaginationRounded({ totalCount, onChange }: PaginationRoundedProps) {
    const [page, setPage] = useState(1);

    const [isMobileMode, setIsMobileMode] = useState<boolean>(false)
    useEffect(() => {
        function resizeRunner() {
            if (window.matchMedia("(max-width: 767px)").matches) {
                setIsMobileMode(true)
            } else {
                setIsMobileMode(false)
            }
        }
        resizeRunner()
        window.addEventListener('resize', resizeRunner)
    }, [])

    useEffect(() => {
        onChange(page);
    }, [onChange, page]);

    function handleJumpToPage(event: React.ChangeEvent<HTMLSelectElement>) {
        setPage(Number(event.target.value));
    }

    function renderItem(params: PaginationRenderItemParams): React.ReactNode {
        if (params.type.includes('ellipsis')) {
            return <div className="min-w-8 px-2 h-8 flex items-center justify-center text-sm font-semibold">...</div>
        }
        return (
            <div
                onClick={() => {
                    if (params.disabled) return;
                    setPage(Number(params.page));
                }}
                className={
                    `min-w-8 px-2 h-8 mx-0.5 flex items-center justify-center rounded-lg font-semibold 
                    ${(isMobileMode && (params.page ?? 0) > 99) ? 'text-xs' : 'text-sm'}
                    ${params.selected ? 'text-white bg-indigo-600' : 'text-gray-700 border border-solid border-gray-200'} 
                    ${params.disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`
                }
            >
                {params.type.includes('previous') && <img src='/images/icon-pre.svg' alt="" />}
                {params.type.includes('next') && <img src='/images/icon-next.svg' alt="" />}
                {params.type.includes('first') && <img src='/images/icon-first.svg' alt="" />}
                {params.type.includes('last') && <img src='/images/icon-last.svg' alt="" />}
                {params.type.includes('page') && params.page}
            </div>
        );
    }

    return (
        <Stack direction="row" spacing={2} className='w-full flex justify-center'>
            <Pagination
                boundaryCount={isMobileMode ? 0 : 1}
                count={totalCount}
                page={page}
                variant="outlined"
                shape="rounded"
                showFirstButton
                showLastButton
                renderItem={renderItem}
            />
            {
                !isMobileMode && totalCount <= 200 && <Stack direction="row" alignItems="center" spacing={2}>
                    <div className="text-gray-700 text-sm font-semibold">Page</div>
                    <div className="relative">
                        <select
                            value={page}
                            onChange={handleJumpToPage}
                            className="pl-3 pr-8 py-1.5 rounded-lg border border-solid border-gray-300 text-sm font-semibold appearance-none cursor-pointer"
                        >
                            {Array.from({ length: totalCount }, (_, i) => i + 1).map((page) => (
                                <option key={page} value={page}>
                                    {page}
                                </option>
                            ))}
                        </select>
                        <img className="absolute top-1/2 -translate-y-2/4 right-4 pointer-events-none"
                            src='/images/icon-arrow-down.svg' alt="" />
                    </div>
                    <div className="text-gray-700 text-sm font-semibold">of 100</div>
                </Stack>
            }
        </Stack>
    );
}