import { Types } from "@anyvm/moveup-sdk";
import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Web3 from "web3";
import { CoinBalances } from "../model/CoinBalances";
import { CurrentTokenOwnerships } from "../model/CurrentTokenOwnerships";
import { ModuleModel, PackageModel } from "../model/PackageModel";
import { AddressInfoTabs } from "../page/AddressInfoPage";
import { apiService } from "../utils/httpHelper";
import { useAppContext } from "./AppContext";

export interface AddressInfo {
    showLoading: boolean,
    ethBalance: string,
    txs: Types.Transaction[],
    coins: CoinBalances[],
    tokens: CurrentTokenOwnerships[],
    accountResources: Types.MoveResource[],
    packages: PackageModel[],
    accountInfo: Types.AccountData,
    currentTotalPage: number,
    onPageChange: (tabValue: number, page: number) => void,
}
const pageCount = 10;
const AddressContext = createContext<AddressInfo>({} as AddressInfo);
const AddressContextProvider = ({ children }: { children: ReactNode }): JSX.Element => {
    const params = useParams();
    const address = params.addressId;
    const appContext = useAppContext();

    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [txs, setTxs] = useState<Types.Transaction[]>([]);
    const [coins, setCoins] = useState<CoinBalances[]>([]);
    const [tokens, setTokens] = useState<CurrentTokenOwnerships[]>([]);
    const [packages, setPackages] = useState<PackageModel[]>([]);
    const [ethBalance, setETHBalance] = useState<string>('0');
    const [accountInfo, setAccountInfo] = useState<Types.AccountData>({} as Types.AccountData);
    const [accountResources, setAccountResources] = useState<Types.MoveResource[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentTotalPage, setCurrentTotalPage] = useState<number>(1);
    const [tabValue, setTabValue] = useState<number>(AddressInfoTabs.transactions);

    const fetchBalance = useCallback(async () => {
        try {
            const web3 = new Web3('https://mainnet.infura.io/v3/4b2ff511773842be8b403a72f6460f3d');
            const balance = await web3.eth.getBalance(address ?? '');
            setETHBalance(web3.utils.fromWei(balance, 'ether'));
        } catch (e) {
            console.error('fetchBalance error', e);
        }
    }, [address]);

    const fetchUserTx = useCallback(async (skip: number) => {
        try {
            const params = { 'account': address, skip: skip, limit: pageCount };
            setShowLoading(true);
            const { data, total } = await apiService.post<{ data?: { version: string }[], total: number }>('/account/tx', params);
            setCurrentTotalPage(Math.ceil(total / pageCount));
            const promises = data?.map<Promise<Types.Transaction>>((r) => {
                return appContext.provider.getTransactionByVersion(Number(r.version));
            }) ?? [];
            const txs = await Promise.all(promises);
            setTxs(txs ?? []);
        } catch (e) {
            console.error('fetchUserTx error: ', e);
        } finally {
            setShowLoading(false);
        }
    }, [address, appContext.provider])

    const fetchCoins = useCallback(async (skip: number) => {
        try {
            const params = { 'account': address, skip: skip, limit: pageCount };
            setShowLoading(true);
            const { data, total } = await apiService.post<{ data?: CoinBalances[], total: number }>('/account/coins', params);
            setCurrentTotalPage(Math.ceil(total / pageCount));
            setCoins(data ?? []);
        } catch (e) {
            console.error('fetchCoins error', e);
        } finally {
            setShowLoading(false);
        }

    }, [address])

    const fetchTokens = useCallback(async (skip: number) => {
        try {
            const params = { 'account': address, skip: skip, limit: pageCount };
            setShowLoading(true);
            const { data, total } = await apiService.post<{ data?: CurrentTokenOwnerships[], total: number }>('/account/tokens', params);
            setCurrentTotalPage(Math.ceil(total / pageCount));
            setTokens(data ?? []);
        } catch (e) {
            console.error('fetchTokens error', e);
        } finally {
            setShowLoading(false);
        }
    }, [address])

    const fetchResources = useCallback(async () => {
        try {
            if (!address) return;
            const resources: Types.MoveResource[] = await appContext.provider.getAccountResources(address);
            setAccountResources(resources);
        } catch (e) {
            console.error('fetchResources error', e);
        }
    }, [address, appContext.provider]);

    const fetchModules = useCallback(async () => {
        try {
            if (!address) return;
            const { data } = await appContext.provider.getAccountResource(address, "0x1::code::PackageRegistry");
            if ('packages' in data && data.packages instanceof Array) {
                let packagesRes: PackageModel[] = [];
                for (const p of data.packages) {
                    let modules: ModuleModel[] = [];
                    for (const module of (p?.modules ?? [])) {
                        modules.push({ name: module.name, source: module.source });
                    }
                    packagesRes.push({ name: p.name, modules: modules });
                }
                setPackages(packagesRes);
            }
        } catch (e) {
            console.error('fetchModules error', e);
        }
    }, [address, appContext.provider])

    const fetchInfo = useCallback(async () => {
        try {
            if (!address) return;
            const info: Types.AccountData = await appContext.provider.getAccount(address);
            setAccountInfo(info);
        } catch (e) {
            console.error('fetchInfo error', e);
        }
    }, [address, appContext.provider]);

    const hidePagination = useCallback(() => {
        setCurrentTotalPage(1);
    }, [])

    const onPageChange = useCallback((tabValue: number, page: number) => {
        setCurrentPage(page);
        setTabValue(tabValue);
    }, [])

    useEffect(() => {
        const skip = (currentPage - 1) * pageCount;
        switch (tabValue) {
            case AddressInfoTabs.transactions:
                fetchUserTx(skip);
                break;
            case AddressInfoTabs.coins:
                fetchCoins(skip);
                break;
            case AddressInfoTabs.tokens:
                fetchTokens(skip);
                break;
            default:
                hidePagination();
                break;
        }
    }, [tabValue, currentPage, address, fetchUserTx, fetchCoins, fetchTokens, hidePagination]);

    useEffect(() => {
        const initAddressInfo = async () => {
            fetchBalance();
            fetchInfo();
            fetchResources();
            fetchModules();
        }
        initAddressInfo();
    }, [address, fetchBalance, fetchInfo, fetchModules, fetchResources]);

    return (
        <AddressContext.Provider value={{
            showLoading: showLoading,
            ethBalance: ethBalance,
            txs: txs,
            coins: coins,
            tokens: tokens,
            accountResources: accountResources,
            packages: packages,
            accountInfo: accountInfo,
            currentTotalPage: currentTotalPage,
            onPageChange: onPageChange,
        }}>
            {children}
        </AddressContext.Provider>
    );
};

export const useAddressContext = () => useContext(AddressContext);
export default AddressContextProvider;
