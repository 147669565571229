import {Link, useLocation, useNavigate} from "react-router-dom";
import Web3 from "web3";
import './Header.scss';
import {ChangeEvent, useEffect, useRef, useState} from "react";
import {CircularProgress, Menu, Popover} from "@mui/material";
import {useAppContext} from "../context/AppContext";
import {Types} from "@anyvm/moveup-sdk";
import {shortAddress} from "../utils/StringHelper";
import {WalletButton} from "./WalletButton";


export function Header() {

    const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false)
    const [showSearch, setShowSearch] = useState<boolean>(false)
    const location = useLocation();
    const navigate = useNavigate();
    const debounceTimer = useRef<any>(null);
    const [searching, setSearching] = useState<boolean>(false)
    const appContext = useAppContext();
    const [effectiveSearchedRes, setEffectiveSearchedRes] = useState<any[]>([])
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [anchorEl2, setAnchorEl2] = useState<HTMLButtonElement | null>(null);
    const seachRef = useRef<any>(null);
    // const seachRef2 = useRef<any>(null);

    const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setAnchorEl2(null);
    };
    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorEl2);
    const id = open ? 'simple-popover' : undefined;
    const id2 = open2 ? 'simple-popover' : undefined;

    const closeMenu = () => {
        setShowMobileMenu(false)
    }

    const [isMobileMode, setIsMobileMode] = useState<boolean>(false)
    useEffect(() => {
        function resizeRunner() {
            if (window.matchMedia("(max-width: 767px)").matches) {
                setIsMobileMode(true)
            } else {
                setIsMobileMode(false)
            }
        }
        resizeRunner()
        window.addEventListener('resize', resizeRunner)
    }, [])

    useEffect(() => {
        setShowMobileMenu(false)
        if (location.pathname === '/') {
            setShowSearch(false)
        } else {
            setShowSearch(true)
        }
    }, [location])
    useEffect(() => {
        const rootContainer = document.getElementById('root')
        if (showSearch) {
            if (showMobileMenu) {
                if (rootContainer && !rootContainer.classList.contains('mobile-menu-showing2')) {
                    rootContainer.classList.add('mobile-menu-showing2')
                }
            } else {
                if (rootContainer && rootContainer.classList.contains('mobile-menu-showing2')) {
                    rootContainer.classList.remove('mobile-menu-showing2')
                }
            }
        } else {
            if (showMobileMenu) {
                if (rootContainer && !rootContainer.classList.contains('mobile-menu-showing')) {
                    rootContainer.classList.add('mobile-menu-showing')
                }
            } else {
                if (rootContainer && rootContainer.classList.contains('mobile-menu-showing')) {
                    rootContainer.classList.remove('mobile-menu-showing')
                }
            }
        }
    }, [showMobileMenu, showSearch])
    function onSearchInputed(event: ChangeEvent<HTMLInputElement>): void {
        if (!debounceTimer.current) {
            debounceTimer.current = setTimeout(() => {
                debounceTimer.current = null;
                doSearchData(event.target.value)
            }, 1500);
        } else {
            clearTimeout(debounceTimer.current);
            debounceTimer.current = setTimeout(() => {
                debounceTimer.current = null;
                doSearchData(event.target.value)
            }, 1500);
        }
    }
    const doSearchData = async (searchContent: string) => {
        const res = [];
        setSearching(true)
        if (!searchContent.startsWith('0x') && !isNaN(Number(searchContent))) {
            const numberContent = Number(searchContent);
            try {
                const resBlockByHeight = await appContext.provider.getBlockByHeight(numberContent);
                if (resBlockByHeight) {
                    res.push({ type: 'Block', data: resBlockByHeight, search: searchContent })
                }
            } catch (e: any) { }
            try {
                const resBlockByVersion = await appContext.provider.getBlockByVersion(numberContent);
                if (resBlockByVersion) {
                    res.push({ type: 'Block with txn version', data: resBlockByVersion, search: searchContent })
                }
            } catch (e: any) { }
        }
        if (!searchContent.startsWith('0x')) {
            const searchContent2 = `0x${searchContent}`;
            try {
                const resAccount = await appContext.provider.getAccount(searchContent2);
                if (resAccount) {
                    res.push({ type: 'Account', data: resAccount, search: searchContent })
                }
            } catch (e: any) { }
        } else {
            try {
                const resAccount = await appContext.provider.getAccount(searchContent);
                if (resAccount) {
                    res.push({ type: 'Account', data: resAccount, search: searchContent })
                }
            } catch (e: any) { }
        }
        try {
            const resTx = await appContext.provider.getTransactionByHash(searchContent);
            if (resTx) {
                res.push({ type: 'Transaction', data: resTx, search: searchContent })
            }
        } catch (e: any) { }
        setSearching(false)
        if (res.length <= 0 && Web3.utils.checkAddressChecksum(searchContent)) {
            res.push({ type: 'Account', data: searchContent, search: searchContent })
        }
        if (res.length <= 0) {
            res.push({ type: 'No Results', data: undefined, search: undefined })
        }
        if (res && res.length > 0) {
            setEffectiveSearchedRes(res)
        }
    }
    function getLinkByData(r: { type: string; search: string; data: any }): string {
        switch (r.type) {
            case 'Block':
            case 'Block with txn version':
                return `/block/${(r.data as Types.Block).block_height}/overview`;
            case 'Account':
                return `/address/${r.search}/transactions`;
            case 'No Results':
                return ``;
            case 'Transaction':
            default:
                return `/tx/${(r.data as Types.Transaction).hash}`;
        }
    }
    return (
        <>
            <div className="h-0 relative">
                <div className='absolute hidden md:flex h-auto inset-x-0 top-0 flex flex-row items-center header-container-mobile border-b border-solid border-white'>
                    <Link className="mr-2 shrink-0" to="/">
                        <img src='/images/logo_full.svg' width={84} alt="" />
                    </Link>
                    <div className="flex-1"></div>
                    <div className="cursor-pointer" onClick={() => setShowMobileMenu(!showMobileMenu)}><img src={'/images/more.svg'} width={24} height={24} alt="" /></div>
                    <div className={`absolute left-0 z-20 mobile-menu-bg flex flex-col w-full items-end ${showMobileMenu ? 'h-auto' : 'h-0 overflow-hidden'}`}>
                        {(isMobileMode && showSearch) && <div className="self-stretch flex-[1_1_0] flex items-center bg-white rounded-lg basis-auto px-2 overflow-hidden mx-8 mt-2" ref={seachRef} style={{ height: '44px' }}>
                            {searching ? <CircularProgress size={20} className="mx-1 mt-1" /> : <img src='/images/search.svg' width={28} height={28} />}
                            <input
                                onChange={onSearchInputed}
                                className='flex-1 outline-none ml-2 text-placeholder font-extralight'
                                placeholder={isMobileMode ? 'Addr / Txn Hash / Block / Token' : 'Search by Address / Txn Hash / Block / Token'} />
                        </div>}
                        <Link className='text-gray-900 mr-8 font-bold text-base shrink py-2' to="/txs" onClick={closeMenu}>
                            Transactions
                        </Link>
                        <Link className='text-gray-900 mr-8 font-bold text-base shrink py-2' to="/analytics" onClick={closeMenu}>
                            Analytics
                        </Link>
                        <Link className='text-gray-900 mr-8 font-bold text-base shrink py-2' to="/blocks" onClick={closeMenu}>
                            Blocks
                        </Link>
                        <div className='text-gray-900 mr-8 font-bold text-base shrink py-2 flex flex-row items-center cursor-pointer' onClick={(e: any) => handleClick(e)}>
                            <div>Blockchain</div>
                            <img className='ml-0.5' src="/images/arrow-down.svg" width={18} height={18} alt="" />
                        </div>
                        <div className='text-gray-900 mr-8 font-bold text-base shrink py-2 flex flex-row items-center' onClick={closeMenu}>
                            <div>Mainnet</div>
                            <img className='ml-0.5' src="/images/arrow-down.svg" width={18} height={18} alt="" />
                        </div>
                    </div>
                </div>
                <div className='absolute flex md:hidden h-2 inset-x-0 top-0 flex flex-row items-center header-container border-b border-solid border-white'>
                    <Link className="mr-4 shrink-0" to="/">
                        <img src='/images/logo_full.svg' width={140} alt="" />
                    </Link>
                    {(!isMobileMode && showSearch) && <div className="flex-[5_0_0] flex h-11 items-center bg-white rounded-lg px-2 overflow-hidden mr-2" style={{ minWidth: '300px', maxWidth: '440px' }} ref={seachRef}>
                        {searching ? <CircularProgress size={20} className="mx-1" /> : <img src='/images/search.svg' width={28} height={28} />}
                        <input
                            onChange={onSearchInputed}
                            className='flex-1 outline-none ml-2 text-placeholder text-sm'
                            placeholder={isMobileMode ? 'Addr / Txn Hash / Block / Token' : 'Search by Address / Txn Hash / Block / Token'} />
                    </div>}
                    <div className="flex-1"></div>
                    <div className="flex flex-row shrink flex-[1_0_auto] justify-end flex-wrap" style={{ maxWidth: '580px' }}>
                        <Link className='text-gray-900 mr-8 font-bold text-base shrink' to="/txs">
                            Transactions
                        </Link>
                        <Link className='text-gray-900 mr-8 font-bold text-base shrink' to="/analytics">
                            Analytics
                        </Link>
                        <Link className='text-gray-900 mr-8 font-bold text-base shrink' to="/blocks">
                            Blocks
                        </Link>
                        <div className='text-gray-900 mr-8 font-bold text-base shrink flex flex-row items-center cursor-pointer' onClick={(e: any) => handleClick(e)}>
                            <div>Blockchain</div>
                            <img className='ml-0.5' src="/images/arrow-down.svg" width={18} height={18} alt="" />
                        </div>
                        <div className='text-gray-900 mr-8 font-bold text-base shrink flex flex-row items-center'>
                            <div>Testnet</div>
                            {/* <img className='ml-0.5' src="/images/arrow-down.svg" width={18} height={18} alt="" /> */}
                        </div>
                    </div>
                    <WalletButton />
                </div >
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div className="my-2">
                    <div onClick={() => {
                        handleClose()
                    }} className="items-center cursor-pointer px-4 text-sm flex flex-row w-48 text-center leading-11 h-11 border-b border-solid border-gray-100">
                        <div>Mainnet</div>
                    </div>
                    <div onClick={() => {
                        handleClose()
                    }} className="items-center cursor-pointer px-4 text-sm flex flex-row w-48 text-center leading-11 h-11">
                        <div>Testnet</div>
                    </div>
                </div>
            </Popover>
            <Popover
                id={id2}
                open={open2}
                anchorEl={anchorEl2}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div className="my-2">
                    <div onClick={() => {
                        handleClose()
                        navigate('/tx-l1-2')
                    }} className="items-center cursor-pointer px-4 text-sm flex flex-row w-60 text-center leading-11 h-11 border-b border-solid border-gray-100">
                        <div>Latest L1→L2 Transactions</div>
                    </div>
                    <div onClick={() => {
                        handleClose()
                        navigate('/tx-l2-1')
                    }} className="items-center cursor-pointer px-4 text-sm flex flex-row w-60 text-center leading-11 h-11 border-b border-solid border-gray-100">
                        <div>Latest L2→L1 Transactions</div>
                    </div>
                    <div onClick={() => {
                        handleClose()
                        navigate('/l1/assets')
                    }} className="items-center cursor-pointer px-4 text-sm flex flex-row w-60 text-center leading-11 h-11 border-b border-solid border-gray-100">
                        <div>Supported L1 Assets List</div>
                    </div>
                    <div onClick={() => {
                        handleClose()
                        navigate('/coins')
                    }} className="items-center cursor-pointer px-4 text-sm flex flex-row w-60 text-center leading-11 h-11">
                        <div>Top Coins</div>
                    </div>
                </div>
            </Popover>
            <Menu
                id="basic-menu"
                className='mt-1 rounded-md'
                anchorEl={seachRef.current}
                open={(seachRef.current && effectiveSearchedRes && effectiveSearchedRes.length > 0)}
                onClose={() => setEffectiveSearchedRes([])}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}

            >
                {effectiveSearchedRes.map((r: { type: string, search: string, data: Types.Block | Types.Transaction | Types.AccountData }) => {
                    const route1 = getLinkByData(r)
                    if (!route1) {
                        return <div className='flex flex-row justify-stretch' style={isMobileMode ? { width: '100%' } : { width: '620px' }} onClick={() => setEffectiveSearchedRes([])}>
                            <div className='cursor-pointer hover:bg-gray-100 h-12 leading-8 px-4 py-2 w-full' >
                                {r.type}
                            </div>
                        </div>
                    }
                    return <div className='flex flex-row justify-stretch' style={isMobileMode ? { width: '100%', minWidth: '300px' } : { width: '620px' }} onClick={() => setEffectiveSearchedRes([])}>
                        <Link to={route1} className='cursor-pointer hover:bg-gray-100 h-12 leading-8 px-4 py-2 w-full' >
                            {r.type}:{' '}{shortAddress(r.search)}
                        </Link>
                    </div>
                })}
            </Menu>
        </>
    );
}
