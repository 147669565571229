import { HexString, Types } from "@anyvm/moveup-sdk";
import pako from "pako";

// 8 entry functions | Bytecode: 15.93 KB
export function getEntryFunctions(funcs: Types.MoveFunction[]): number {
    return funcs?.filter((fn) => fn.is_entry)?.length ?? 0;
}

export function getBytecodeSizeInKB(bytecodeHex: string): number {
    if (!bytecodeHex || bytecodeHex.length === 0) return 0;
    // Convert the hex string to a byte array
    const textEncoder = new TextEncoder();
    const byteArray = new Uint8Array(textEncoder.encode(bytecodeHex));

    // Compute the size of the byte array in kilobytes (KB)
    const sizeInKB = byteArray.length / 1024;

    // Return the size in KB with two decimal places
    return parseFloat(sizeInKB.toFixed(2));
}

export function transformCode(source: string): string {
    return pako.ungzip(new HexString(source).toUint8Array(), { to: "string" });
}
