import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import '../tx.scss';
import { Types } from '@anyvm/moveup-sdk';
import { CircularProgress, Collapse } from '@mui/material';
import { useAppContext } from '../../../context/AppContext';
import { useSnackbar } from '../../../context/SnackbarContext';
import ReactJson from 'react-json-view';
import { useJsonViewCardTheme } from './TxOverviewTab';
import { NoDataFound } from '../../../component/NoDataFound';
type TxChangeTabProps = {
    transaction: Types.Transaction;
};
export function TxEventsTab({ transaction }: TxChangeTabProps) {
    const params = useParams();
    const appContext = useAppContext();
    const showSnackbar = useSnackbar();
    const [open, setOpen] = React.useState<{ [key: string]: boolean }>({});
    const jsonViewCardTheme = useJsonViewCardTheme();

    const { events } = transaction as Types.UserTransaction;

    useEffect(() => {
        if ((events || []).length > 0) {
            setOpen({ 0: true })
        }
    }, [events])

    const renderEvents = useMemo(() => {
        if (!events || events.length <= 0) {
            return <NoDataFound />
        }
        return events.map((event: Types.Event, index: number) => {
            return <div className='w-full'>
                <div className={`w-full flex flex-row h-15 items-center panel-collapse-title px-5 -mx-5 md:pl-5 md:-mx-0 text-sm text-item-head text-grey-600 cursor select-none border-solid border-gray-200 ${index >= events.length - 1 ? '' : 'border-b'}`}
                    onClick={() => setOpen({ ...open, [index]: !open[`${index}`] })}>
                    <div className='w-60 md:w-36'>Index: </div>
                    <div className='flex-2'>{index}</div>
                    <img className={`ml-0.5 arrow-down ${!open[`${index}`] && 'not-opened'}`} src="/images/arrow-down.svg" width={18} height={18} />
                </div>
                <Collapse in={!!open[`${index}`]} timeout="auto" unmountOnExit>
                    <div className='flex flex-row h-15 items-center text-sm text-grey-600 border-b border-solid border-gray-100'>
                        <div className='text-item-head w-60 md:w-36'>Account Address: </div>
                        <Link className='flex-2 flex flex-row text-indigo-900 cursor-pointer' to={`/address/${event.guid.account_address}/transactions`}>{event.guid.account_address}</Link>
                    </div>
                    <div className='flex flex-row h-15 items-center text-sm text-grey-600 border-b border-solid border-gray-100'>
                        <div className='text-item-head w-60 md:w-36'>Creation Number: </div>
                        <div className='text-black'>{event.guid.creation_number}</div>
                    </div>
                    <div className='flex flex-row h-15 items-center text-sm text-grey-600 border-b border-solid border-gray-100'>
                        <div className='text-item-head w-60 md:w-36'>Sequence Number: </div>
                        <div className='text-black'>{event.sequence_number}</div>
                    </div>
                    <div className='flex flex-row h-15 items-center text-sm text-grey-600 border-b border-solid border-gray-100'>
                        <div className='text-item-head w-60 md:w-36'>Type:</div>
                        <div className='text-black'>{event.type}</div>
                    </div>
                    <div className='flex flex-row h-auto items-center text-sm text-grey-600'>
                        <div className='text-item-head w-60 md:w-36 self-start h-15 flex flex-row items-center'>Data:</div>
                        <div className='flex-2 flex flex-row text-black overflow-hidden'>
                            <div style={{ backgroundColor: '#EDEDF7' }} className='rounded py-1 my-3 overflow-auto'>
                                <ReactJson
                                    src={event.data as any}
                                    theme={jsonViewCardTheme}
                                    name={null}
                                    displayObjectSize={false}
                                    displayDataTypes={false}
                                    quotesOnKeys={false}
                                    style={{
                                        fontSize: '14px',
                                        color: '#66568A'
                                    }}
                                    collapsed={false}
                                />
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>
        })
    }, [events, open])
    return (
        <div className='pt-5 md:overflow-x-auto'>
            <div className='flex flex-col justify-stretch min-w-full w-fit'>
                {renderEvents}
            </div>
        </div>
    );
}
