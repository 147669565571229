export function CloseButton({ width = 32, onClose }: { width?: number, onClose: () => void }) {

    return (
        <img
            className="cursor-pointer hover:bg-gray-100 hover:rounded-lg"
            src='/images/icon-close.svg'
            width={width}
            onClick={onClose}
            alt=""
        />
    )
}