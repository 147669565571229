import { NoDataFound } from "./NoDataFound";

export function EmptyView({ title }: { title: string }) {
    return (
        <div className='w-full h-full flex flex-col items-center min-h-halfScreen'>
            <div className='w-full max-w-7xl h-full overflow-y-auto flex flex-col'>
                <div className='mt-16 mb-8 text-left text-4xl font-extrabold'>{title}</div>
                <div className='w-full bg-white rounded-2xl shadow-md overflow-hidden md:overflow-x-auto'>
                    <NoDataFound />
                </div>
            </div>
        </div>
    );
}