import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { CopyIcon } from "./CopyIcon";
import { isMobile } from "react-device-detect";

export function TransactionsTitle() {
    return (
        <div className='h-16 flex flex-row items-center'>
            <div className='text-sm font-semibold flex-1 text-gray-600 md:w-28 md:flex-none'>INDEX</div>
            <div className='text-sm font-semibold flex-[2_2_0%] text-gray-600 md:w-32 md:flex-none'>TIMESTAMP</div>
            <div className='text-sm font-semibold flex-[2_2_0%] text-gray-600 md:w-56 md:flex-none'>SENDER</div>
            <div className='text-sm font-semibold flex-[2_2_0%] text-gray-600 md:w-56 md:flex-none'>SENT TO</div>
            <div className='text-sm font-semibold flex-[3_3_0%] text-gray-600 md:w-60 md:flex-none'>FUNCTION</div>
            <div className='text-sm font-semibold flex-1 text-gray-600 md:w-28 md:flex-none'>AMOUNT</div>
        </div>
    );
}

export interface TransactionsItemParams {
    version: string,
    timeAgo: string,
    sender: string,
    senderShort: string,
    sendTo: string,
    sendToShort: string,
    funcStr: string,
    funcRoute: string,
    amount: string,
    gas: string,
}

export function TransactionsItem(params: TransactionsItemParams) {
    return (
        <div className='h-16 flex flex-col'>
            <div className='w-full h-px bg-gray-200 md:w-264' />
            <div className='h-16 flex flex-row items-center'>
                <Link
                    to={`/tx/${params.version}`}
                    className='flex-1 flex flex-row items-center text-indigo-900 text-sm font-semibold hover:text-indigo-600 md:w-28 md:flex-none'
                >
                    {params.version}
                </Link>
                <div className='flex-[2_2_0%] flex flex-row items-center text-gray-700 text-sm font-semibold md:w-32 md:flex-none'>
                    <div>{params.timeAgo}</div>
                </div>
                <div className='flex-[2_2_0%] flex flex-row items-center text-indigo-900 text-sm font-semibold hover:text-indigo-600 md:w-56 md:flex-none'>
                    <Tooltip title={params.sender} placement="top">
                        <Link to={`/address/${params.sender}/transactions`}>{params.senderShort}</Link>
                    </Tooltip>
                    {params.sender && <CopyIcon text={params.sender} />}
                </div>
                <div className='flex-[2_2_0%] flex flex-row items-center text-indigo-900 text-sm font-semibold hover:text-indigo-600 md:w-56 md:flex-none'>
                    <Tooltip title={params.sendTo} placement="top">
                        <Link to={`/address/${params.sendTo}/transactions`}>{params.sendToShort}</Link>
                    </Tooltip>
                    {true && <CopyIcon text={params.sendTo} />}
                </div>
                <div className='flex-[3_3_0%] flex flex-row items-center text-indigo-900 text-sm font-semibold md:w-60 md:flex-none'>
                    <Tooltip title={params.funcStr} placement="top">
                        <Link to={params.funcRoute}>{params.funcStr}</Link>
                    </Tooltip>
                </div>
                <div className='flex-1 md:w-28 md:flex-none '>
                    <div className="text-gray-700 text-sm font-semibold text-right whitespace-nowrap mr-1">{params.amount}</div>
                    <div className="text-gray-600 text-xs font-semibold text-right whitespace-nowrap">{params.gas}</div>
                </div>
                {isMobile && <div className="w-5 flex-none" />}
            </div>
        </div>
    );
}