import { Outlet } from 'react-router-dom';
import { Header } from '../../component/Header';
import './HomePage.scss';
import { Footer } from '../../component/Footer';

export default function HomePage() {

    return (
        <div className="App">
            <Header></Header>
            <div className='homepage-top-container'>
                <div className='homepage-top-gb'>
                    <div className='absolute homepage-top-gb-content'></div>
                </div>
            </div>
            <div className='page-container relative max-w-7xl m-auto px-4 mb-8'>
                <Outlet />
            </div>
            <Footer />
        </div>
    );
}
