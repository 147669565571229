import { useCallback, useEffect, useState } from "react";
import { PaginationRounded } from "../component/PaginationRounded";
import Web3 from "web3";
import { libAddressManagerABI } from "../abi/libAddressManagerABI";
import { SupportedAssetsModel } from "../model/SupportedAssets";
import { erc20ABI } from "../abi/erc20ABI";
import { CircularProgress } from "@mui/material";
import { NoDataFound } from "../component/NoDataFound";


export function SupportedAssetsList() {
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [supportedAssets, setSupportedAssets] = useState<SupportedAssetsModel[]>([]);

    const contractAddress = '0x300949214Fff0e4fA0cfc091e6Aa95EA13691e01';
    const web3 = new Web3('https://sepolia.infura.io/v3/ab8d37cbb6e24b759f03cb980648b42a');

    const iconList = new Map();
    iconList.set('Link', '/images/icon-chainlink.png');
    iconList.set('ETH', '/images/icon-eth.png');

    const loadSupportAsset = useCallback(async (token: string): Promise<SupportedAssetsModel> => {
        try {
            if (token === '0x0000000000000000000000000000000000000000') {
                return { l1Name: '', l2Name: '', supply: '' };
                // return { l1Name: 'ETH', l2Name: '0x1::eth::ETH', supply: '000' };
            }
            const contract = new web3.eth.Contract(libAddressManagerABI, contractAddress);
            const l2Name = await contract.methods.getSupportCoinType(token).call();
            const erc20Contract = new web3.eth.Contract(erc20ABI, token);
            const l1Name = await erc20Contract.methods.name().call();
            const totalSupply = await erc20Contract.methods.totalSupply().call();
            return { l1Name: l1Name, l2Name: l2Name, supply: totalSupply };
        } catch (e) {
            console.error(`loadSupportAsset ${token} error: `, e);
            return { l1Name: '', l2Name: '', supply: '' };
        }
    }, [web3.eth.Contract]);

    const loadSupportList = useCallback(async () => {
        try {
            setShowLoading(true);

            const contract = new web3.eth.Contract(libAddressManagerABI, contractAddress);
            const tokens = await contract.methods.getAllSupportL1TokenAddr().call();
            console.log('fetchLayerTransactions res: ', tokens);

            if (Array.isArray(tokens)) {
                const supportedAssets = await Promise.all(tokens.map(loadSupportAsset));
                const validAssets = supportedAssets.filter(e => e.l1Name);
                console.log('validAssets res: ', validAssets);
                setSupportedAssets(validAssets);
            }
        } catch (e) {
            console.error('fetchLayerTransactions error', e);
        } finally {
            setShowLoading(false);
        }
    }, [loadSupportAsset, web3.eth.Contract])

    useEffect(() => {
        loadSupportList();
    }, [])

    return (
        <div className='w-full h-full flex flex-col items-center min-h-screen'>
            <div className='w-full max-w-7xl h-full overflow-y-auto flex flex-col'>
                <div className='mt-16 mb-8 text-left text-4xl font-extrabold'>Latest Blocks</div>
                <div className='w-full bg-white rounded-2xl shadow-md overflow-hidden md:overflow-x-auto'>
                    <div className='h-16 flex flex-row items-center pl-5'>
                        <div className='font-semibold flex-1 text-gray-600 md:w-28 md:flex-none'>L1 ASSET</div>
                        <div className='font-semibold flex-2 text-gray-600 md:w-36 md:flex-none'>L2 ASSET</div>
                        <div className='font-semibold flex-1 text-gray-600 md:w-28 md:flex-none'>SUPPLY</div>
                    </div>
                    {
                        supportedAssets && supportedAssets.map((e: SupportedAssetsModel, i: number) => {
                            const [_, __, chainName] = e.l2Name.split("::");
                            return (
                                <div key={`support-assets-list-page-item-${i}`} >
                                    <div className='w-full h-px bg-gray-200 md:w-149' />
                                    <div className='h-16 flex flex-row items-center pl-5'>
                                        <div className='flex items-center font-semibold flex-1 text-gray-600 md:w-28 md:flex-none'>
                                            <img src={iconList.get(chainName)} width={32} className='max-w-none' alt="" />
                                            <div className="w-1" />
                                            <span>{e.l1Name}</span>
                                        </div>
                                        <div className='flex items-center inline-block font-semibold flex-2 text-gray-600 md:w-36 md:flex-none'>
                                            <img src={iconList.get(chainName)} width={32} alt="" />
                                            <div className="w-1" />
                                            <span>{chainName}</span>
                                        </div>
                                        <div className='font-semibold flex-1 text-gray-600 md:w-28 md:flex-none'>{`$ ${e.supply}`}</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    {!showLoading && supportedAssets.length <= 0 && <NoDataFound />}
                    {showLoading && <div className='my-28 flex justify-center items-center'><CircularProgress /></div>}
                </div>
                <div className='h-32 md:h-10' />
            </div>
        </div>
    );
}