import { Types } from "@anyvm/moveup-sdk";
import { UseQueryResult, useQuery } from "react-query";
import { useAppContext } from "../context/AppContext";
import { ResponseError, withResponseError } from "../dev/client";
import { moveupCoinType } from "../constants/Constants";
import BigNumber from "bignumber.js";
import { MOVEUP_DECIMALS } from "../utils/TransactionsUtils";

interface CoinStore {
    coin: {
        value: string;
    };
}

export function useGetAccountResources(
    address: string,
): UseQueryResult<Types.MoveResource[], ResponseError> {
    const appContext = useAppContext();

    const getAccountResources = async (address: string): Promise<Types.MoveResource[]> => {
        return withResponseError(
            appContext.provider.getAccountResources(address),
        );
    }

    return useQuery<Array<Types.MoveResource>, ResponseError>(
        ["accountResources", { address }, appContext.network],
        () => getAccountResources(address),
    );
}

export function useGetAccountMoveUpBalance(address: Types.Address) {
    const { isLoading, data, error } = useGetAccountResources(address);

    if (isLoading || error || !data || !Array.isArray(data)) {
        return null;
    }

    const coinStore = data.find(
        (resource) => resource.type === `0x1::coin::CoinStore<${moveupCoinType}>`,
    );

    if (!coinStore) {
        return null;
    }

    const coinStoreData: CoinStore = coinStore.data as CoinStore;

    const coinValue = coinStoreData?.coin?.value;
    const finalRes = new BigNumber(coinValue).shiftedBy(-MOVEUP_DECIMALS).toString(10)
    return finalRes;
}
