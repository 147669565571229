import moment from "moment";

moment.updateLocale('en', {
    relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: 'a secs ago',
        ss: '%d secs',
        m: "1 min",
        mm: "%d mins",
        h: "1 hr",
        hh: "%d hrs",
        d: "1 day",
        dd: "%d days",
        M: "a month",
        MM: "%d months",
        y: "a year",
        yy: "%d years"
    }
});

function ensureMillisecondTimestamp(timestamp: string): number {
    if (timestamp.length > 13) {
        timestamp = timestamp.slice(0, 13);
    }
    if (timestamp.length === 10) {
        timestamp = timestamp + "000";
    }
    return parseInt(timestamp);
}

export const timeFromNow = (timestamp: string | Date) => {
    const now = moment(Date());
    const createdTime = moment(timestamp instanceof Date ? timestamp : ensureMillisecondTimestamp(timestamp));
    const diffTime = now.diff(createdTime);
    const seconds = diffTime / 1000;
    if (seconds <= 60) return `${Math.round(seconds)} secs ago`;
    return createdTime.fromNow();
}