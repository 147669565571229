import { useNavigate } from "react-router-dom";

export function BackButton() {
    const navigate = useNavigate();

    return (
        <div className="flex flex-row">
            <div className="flex flex-row cursor-pointer" onClick={() => navigate(-1)}>
                <img src='/images/arrow-left.svg' width={20} alt="" />
                <div className="w-3" />
                <div className="text-gray-700 text-sm font-semibold">Back</div>
            </div>
            <div className="flex-1" />
        </div>
    );
}