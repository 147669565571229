import { useCallback, useEffect, useRef, useState } from "react";
import { useWallet } from "../context/WalletContext";
import { AccountDialog } from "./AccountDialog";
import { WalletsDialog } from "./WalletsDialog";
import { shortAddress } from "../utils/StringHelper";

export function WalletButton() {
    const { account, connected } = useWallet();
    const [showWalletsModal, setShowWalletsModal] = useState<boolean>(false);
    const [showAccountModal, setShowAccountModal] = useState<boolean>(false);

    const prevConnectedRef = useRef<boolean>();

    const onClickWallet = useCallback(() => {
        if (connected) {
            setShowAccountModal(true);
        } else {
            setShowWalletsModal(true);
        }
    }, [connected])

    useEffect(() => {
        const prevConnected = prevConnectedRef.current;
        if (typeof prevConnected === 'undefined') return;
        if (connected !== prevConnected) {
            if (connected && showWalletsModal) {
                setShowWalletsModal(false);
                setShowAccountModal(true);
            } else {
                setShowAccountModal(false);
            }
        }
    }, [account, connected, showWalletsModal]);

    useEffect(() => {
        prevConnectedRef.current = connected;
    }, [connected]);

    return (
        <div>
            <div
                onClick={onClickWallet}
                className='button-connect-wallet flex flex-row items-center px-6 py-3 rounded-lg cursor-pointer shrink-0'
            >
                <img src="/images/wallet.svg" width={22} height={22} alt="" />
                <div className='text-white ml-2 font-bold text-base'>
                    {connected ? `${shortAddress(account?.address)}` : 'Connect Wallet'}
                </div>
            </div>
            {
                showWalletsModal && <WalletsDialog
                    handleClose={() => setShowWalletsModal(false)}
                    modalOpen={showWalletsModal}
                />
            }
            {
                showAccountModal && <AccountDialog
                    handleClose={() => setShowAccountModal(false)}
                    modalOpen={showAccountModal}
                />
            }
        </div>
    );
}