import { useCallback, useEffect, useState } from "react";
import { CoinInfo } from "../model/CoinBalances";
import { apiService } from "../utils/httpHelper";
import { PaginationRounded } from "../component/PaginationRounded";
import { CircularProgress } from "@mui/material";

const pageCount = 10;
type CoinsResult = { data?: CoinInfo[], total: number };
export function CoinsPage() {
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [coins, setCoins] = useState<CoinInfo[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentTotalPage, setCurrentTotalPage] = useState<number>(1);

    const fetchCoins = useCallback(async (skip: number) => {
        console.log('fetchCoins skip', skip);
        console.log('fetchCoins currentPage', currentPage);
        try {
            const params = { skip: skip };
            setShowLoading(true);
            const { data, total } = await apiService.post<CoinsResult>('/coin/tops', params);
            setCoins(data ?? []);
            setTotal(total);
            if (skip === 0) {
                setCurrentTotalPage(Math.ceil(total / pageCount));
            }
        } catch (e) {
            console.error('fetchCoins error', e);
        } finally {
            setShowLoading(false);
        }
    }, [currentPage])

    useEffect(() => {
        console.log('useCallback');
        fetchCoins(0);
    }, [fetchCoins]);

    return (
        <div className='w-full h-full flex flex-col items-center min-h-screen'>
            <div className='w-full max-w-7xl h-full overflow-y-auto flex flex-col'>
                <div className='mt-16 mb-8 text-left text-4xl font-extrabold'>Top Coins</div>
                <div className='w-full bg-white rounded-2xl shadow-md overflow-hidden md:overflow-x-auto'>
                    <div className="pl-5 pt-2 text-xs text-gray-500">{`Showing ${total} Token Contracts (From a total of 144,229 Token Contracts)`}</div>
                    <div className='h-16 flex flex-row items-center px-5'>
                        <div className='font-semibold flex-1 text-gray-600'>#</div>
                        <div className='font-semibold flex-5 text-gray-600'>TOKEN</div>
                        <div className='font-semibold flex-3 text-gray-600'>PRICE</div>
                        <div className='font-semibold flex-3 text-gray-600'>CHANGE (%)</div>
                        <div className='font-semibold flex-3 text-gray-600'>VOLUM (24h)</div>
                        <div className='font-semibold flex-3 text-gray-600'>MARKET CAP</div>
                        <div className='font-semibold flex-2 text-gray-600'>HOLDERS</div>
                    </div>
                    <div>
                        {
                            !showLoading && coins.map((coin, index) => (
                                <div key={`top-coin-page-${index}-${coin.name}`} className="flex flex-col">
                                    <div className='w-full h-px bg-gray-200 md:w-149' />
                                    <div className="flex h-16 px-5 items-center">
                                        <div className='flex-1'>{(index + 1) + ((currentPage - 1) * pageCount)}</div>
                                        <div className='flex-5'>{`${coin.name}(${coin.symbol})`}</div>
                                        <div className='flex-3'>0</div>
                                        <div className='flex-3'>0</div>
                                        <div className='flex-3'>0</div>
                                        <div className='flex-3'>0</div>
                                        <div className='flex-2'>0</div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    {showLoading && <div className='my-28 flex justify-center items-center'><CircularProgress /></div>}
                </div>
                <div className='h-7' />
                {!showLoading && currentTotalPage > 1 && <PaginationRounded totalCount={currentTotalPage} onChange={setCurrentPage} />}
            </div>
        </div>
    )
}