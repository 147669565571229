import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Types } from '@anyvm/moveup-sdk';
import BigNumber from 'bignumber.js';
import '../tx.scss';
import ReactJson from "react-json-view";
import { CircularProgress, Tooltip, useTheme } from '@mui/material';
import { useAppContext } from '../../../context/AppContext';
import { useSnackbar } from '../../../context/SnackbarContext';
import moment from 'moment';
import { getGas } from '../utils';
import { moveupCoinType } from '../../../constants/Constants';
import { MOVEUP_DECIMALS } from '../../../utils/TransactionsUtils';
import { convertMoveUpAddressToEthMode } from '../../../utils/StringHelper';

type T = {
    txData: Types.Transaction,
    blockData?: Types.Block,
}

const TEXT_COLOR_LIGHT = "#66568A";
const TEXT_COLOR_DARK = "#66568A";
const SECONDARY_TEXT_COLOR = "rgba(14,165,233,0.3)";
const TRANSPARENT = "rgba(0,0,0,0)";

const GROUP_ARRAYS_AFTER_LENGTH = 100;
const COLLAPSE_STRINGS_AFTER_LENGTH = 80;
const MAX_CARD_HEIGHT = 500;

export function useJsonViewCardTheme() {
    const theme = useTheme();
    const textColor =
        theme.palette.mode === "dark" ? TEXT_COLOR_DARK : TEXT_COLOR_LIGHT;

    return {
        scheme: "aptos_explorer",
        author: "aptos",
        base00: TRANSPARENT,
        base01: textColor,
        base02: SECONDARY_TEXT_COLOR, // line color
        base03: textColor,
        base04: SECONDARY_TEXT_COLOR, // item count color
        base05: textColor,
        base06: textColor,
        base07: textColor, // key color
        base08: textColor,
        base09: textColor, // value and data type color
        base0A: textColor,
        base0B: textColor,
        base0C: textColor,
        base0D: SECONDARY_TEXT_COLOR, // object triangle color
        base0E: SECONDARY_TEXT_COLOR, // array triangle color
        base0F: textColor, // copy icon color
    };
}

export function TxOverviewTab(props: T) {
    const { txData, blockData } = props;
    const params = useParams();
    const appContext = useAppContext();
    const showSnackbar = useSnackbar();
    const jsonViewCardTheme = useJsonViewCardTheme();

    useEffect(() => {
    }, [])
    const isUserTransactionData = txData.type === 'user_transaction'
    const transactionData = txData as Types.Transaction_UserTransaction;
    const transactionInBlock = txData as Types.BlockMetadataTransaction;
    console.log('transactionData;', transactionData)

    const getFunctionNameByType = (type: string) => {
        switch (type) {
            case 'state_checkpoint_transaction':
                return <div>State Checkpoint</div>;
            default:
                return <div>Block Metadata</div>;
        }
    }

    const renderReceiver = useCallback(() => {
        const payload = transactionData.payload as Types.TransactionPayload_EntryFunctionPayload;
        const typeArgument = payload.type_arguments.length > 0 ? payload.type_arguments[0] : undefined;
        const isAptCoinTransfer =
            (payload.function === "0x1::coin::transfer" ||
                payload.function === "0x1::moveup_account::transfer_coins") &&
            typeArgument === moveupCoinType;
        const isAptCoinInitialTransfer =
            payload.function === "0x1::moveup_account::transfer" ||
            payload.function === "0x1::moveup_account::transfer_coins";
        if (
            (isAptCoinTransfer || isAptCoinInitialTransfer) &&
            payload.arguments.length === 2
        ) {
            const amountArgument = new BigNumber(payload.arguments[1]).shiftedBy(-MOVEUP_DECIMALS).sd(4).toString(10)
            return <>
                <div className='h-15 flex flex-row items-center border-b border-solid border-gray-200'>
                    <div className='shrink-0 text-item-head text-grey-600 w-60 md:w-36'>Receiver</div>
                    <Link to={`/address/${(transactionData.payload as Types.TransactionPayload_EntryFunctionPayload).arguments[0]}/transactions`} className='flex flex-row text-indigo-900 cursor-pointer'>
                        {(transactionData.payload as Types.TransactionPayload_EntryFunctionPayload).arguments[0]}
                    </Link>
                </div>
                <div className='h-15 flex flex-row items-center border-b border-solid border-gray-200'>
                    <div className='shrink-0 text-item-head text-grey-600 w-60 md:w-36 flex flex-row items-center'>
                        Function <span className='ml-1'><Tooltip title="Move function executed in the transaction." placement="right-start" ><img src='/images/attention.svg' width={16} height={16} /></Tooltip></span>
                    </div>
                    <Link to={`/address/0x1/modules`} className='flex flex-row text-indigo-900 cursor-pointer'>
                        {payload.function}
                    </Link>
                </div>
                <div className='h-15 flex flex-row items-center'>
                    <div className='shrink-0 text-item-head text-grey-600 w-60 md:w-36'>Amount</div>
                    <div className='flex flex-row text-black'>
                        {amountArgument}{' '}{'ETH'}
                    </div>
                </div>
            </>
        } else {
            const smartContractAddr = payload.function.split("::")[0];
            return <div className='h-15 flex flex-row items-center'>
                <div className='shrink-0 text-item-head text-grey-600 w-60 md:w-36'>Function</div>
                <Link to={`/address/${smartContractAddr}/modules`} className='flex flex-row text-indigo-900 cursor-pointer'>
                    {payload.function}
                </Link>
            </div>
        }
    }, [txData])
    return (
        <div className='md:overflow-x-auto'>
            <div className='text-sm block w-fit'>
                <div className='mt-5' />
                <div className={`h-15 flex flex-row items-center ${isUserTransactionData ? 'border-b border-solid border-gray-200' : ''}`}>
                    <div className='shrink-0 text-item-head text-grey-600 w-60 md:w-36'>Status</div>
                    <div className='flex-2 flex flex-row'>
                        {transactionData.success ? <>
                            <img src='/images/success.svg' width={16} height={16} />
                            <div className='ml-2 text-green-500'>Success</div>
                        </> : <>
                            <img src='/images/failed.svg' width={16} height={16} />
                            <div className='ml-2 text-red-200'>Failed</div>
                        </>}
                    </div>
                </div>
                {isUserTransactionData ? <>
                    <div className='h-15 flex flex-row items-center border-b border-solid border-gray-200'>
                        <div className='shrink-0 text-item-head text-grey-600 w-60 md:w-36'>Sender</div>
                        <Link to={`/address/${transactionData.sender}/transactions`} className='flex-2 flex flex-row text-indigo-900 cursor-pointer'>
                            {convertMoveUpAddressToEthMode(transactionData.sender)}
                        </Link>
                    </div>
                    {renderReceiver()}
                </> : <div className='h-15 flex flex-row items-center border-b border-solid border-gray-200'>
                    <div className='shrink-0 text-item-head text-grey-600 w-60 md:w-36 flex flex-row items-center'>
                        Function <span className='ml-1'><Tooltip title="Move function executed in the transaction." placement="right-start" ><img src='/images/attention.svg' width={16} height={16} /></Tooltip></span>
                    </div>
                    <Link to={`/address/0x1/modules`} className='flex flex-row text-indigo-900 cursor-pointer'>
                        {getFunctionNameByType(txData.type)}
                    </Link>
                </div>}
                <div className='panel-gap' />
                {!!blockData && <>
                    <div className='h-15 flex flex-row items-center border-b border-solid border-gray-200'>
                        <div className='shrink-0 text-item-head text-grey-600 w-60 md:w-36'>Block</div>
                        <Link to={`/block/${blockData.block_height}/overview`} className='flex flex-row text-indigo-900 cursor-pointer'>
                            {blockData.block_height}
                        </Link>
                    </div>
                    {isUserTransactionData && <>
                        <div className='h-15 flex flex-row items-center border-b border-solid border-gray-200'>
                            <div className='shrink-0 text-item-head text-grey-600 w-60 md:w-36 flex flex-row items-center'>
                                Expired Timestamp <span className='ml-1'><Tooltip title="A transaction ceases to be valid after its expiration time." placement="right-start" ><img src='/images/attention.svg' width={16} height={16} /></Tooltip></span>
                            </div>
                            <div className='flex-2 flex flex-row text-black'>
                                {moment(Number(transactionData.expiration_timestamp_secs) * 1000).format('DD/MM/YYYY hh:mm:ss')}
                            </div>
                        </div>
                    </>}
                    <div className={`h-15 flex flex-row items-center ${isUserTransactionData ? 'border-b border-solid border-gray-200' : ''}`}>
                        <div className='shrink-0 text-item-head text-grey-600 w-60 md:w-36 flex flex-row items-center'>
                            Timestamp <span className='ml-1'><Tooltip title="The machine timestamp of when the block is committed." placement="right-start" ><img src='/images/attention.svg' width={16} height={16} /></Tooltip></span>
                        </div>
                        <div className='flex-2 flex flex-row text-black'>
                            {moment(Number(transactionData.timestamp) / 1000).format('DD/MM/YYYY hh:mm:ss')}
                        </div>
                    </div>
                    {isUserTransactionData && <>
                        <div className='h-15 flex flex-row items-center border-b border-solid border-gray-200'>
                            <div className='shrink-0 text-item-head text-grey-600 w-60 md:w-36'>Gas Fee</div>
                            <div className='flex-2 flex flex-row text-black'>
                                {new BigNumber(getGas(transactionData).toString()).shiftedBy(-MOVEUP_DECIMALS).sd(4).toString(10)}{' ETH'}
                                <span className='text-gray-500 ml-2'>{`(${(txData as Types.BlockMetadataTransaction).gas_used} Gas Units)`}</span>
                            </div>
                        </div>
                        <div className='h-15 flex flex-row items-center border-b border-solid border-gray-200'>
                            <div className='shrink-0 text-item-head text-grey-600 w-60 md:w-36 flex flex-row items-center'>
                                Gas Unit Price
                            </div>
                            <div className='flex-2 flex flex-row text-black'>
                                {new BigNumber((txData as Types.PendingTransaction).gas_unit_price).shiftedBy(-MOVEUP_DECIMALS).sd(4).toString(10)}{' ETH'}
                            </div>
                        </div>
                        <div className='h-15 flex flex-row items-center'>
                            <div className='shrink-0 text-item-head text-grey-600 w-60 md:w-36 flex flex-row items-center'>
                                Max Gas Limit <span className='ml-1'><Tooltip title="The maximum amount of gas the sender is ready to pay for the transaction." placement="right-start" ><img src='/images/attention.svg' width={16} height={16} /></Tooltip></span>
                            </div>
                            <div className='flex-2 flex flex-row text-black'>
                                {Number((txData as Types.PendingTransaction).max_gas_amount).toLocaleString()}{' Gas Units'}
                            </div>
                        </div>
                    </>}
                </>}
                <div className='panel-gap' />
                {isUserTransactionData && <>
                    <div className='h-auto flex md:hidden flex-row items-center border-b border-solid border-gray-200 overflow-hidden' style={{ minHeight: '60px' }}>
                        <div className='shrink-0 text-item-head text-grey-600 w-60 md:w-36 flex flex-row items-center self-start shrink-0 h-15'>
                            Signature
                        </div>
                        <div className='flex-2 flex flex-row text-black overflow-hidden'>
                            <div style={{ backgroundColor: '#EDEDF7' }} className='rounded py-1 my-3 overflow-auto'>
                                <ReactJson
                                    src={transactionData.signature as any}
                                    theme={jsonViewCardTheme}
                                    name={null}
                                    displayObjectSize={false}
                                    displayDataTypes={false}
                                    quotesOnKeys={false}
                                    style={{
                                        fontSize: '14px',
                                        color: '#66568A'
                                    }}
                                    collapsed={false}
                                />
                            </div>
                        </div>
                    </div>
                </>}

                <div className='h-auto md:flex hidden flex-col items-center border-b border-solid border-gray-200 overflow-hidden' style={{ minHeight: '60px' }}>
                    <div className='shrink-0 text-item-head text-grey-600 w-60 md:w-36 flex flex-row items-center self-start shrink-0 h-15'>
                        Signature
                    </div>
                    <div className='flex-2 flex flex-row text-black overflow-hidden'>
                        <div style={{ backgroundColor: '#EDEDF7' }} className='rounded py-1 my-3 overflow-auto'>
                            <ReactJson
                                src={transactionData.signature as any}
                                theme={jsonViewCardTheme}
                                name={null}
                                displayObjectSize={false}
                                displayDataTypes={false}
                                quotesOnKeys={false}
                                style={{
                                    fontSize: '14px',
                                    color: '#66568A'
                                }}
                                collapsed={false}
                            />
                        </div>
                    </div>
                </div>

                <div className='h-15 flex flex-row items-center'>
                    <div className='shrink-0 text-item-head text-grey-600 w-60 md:w-36 flex flex-row items-center'>
                        Hash
                    </div>
                    <div className='flex-2 flex flex-row text-black text-sm'>
                        {txData.hash}
                    </div>
                </div>
            </div>
        </div>
    );
}
