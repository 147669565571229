import { BackButton } from "../component/BackButton";
import { NoDataFound } from "../component/NoDataFound";

export function TokenInfoPage() {
    return (
        <div className="flex flex-col min-h-screen">
            <div className="h-16" />
            <BackButton />
            <div className="h-7" />
            <div className="text-left text-4xl font-extrabold">Token</div>
            <div className="h-7" />
            <div className="w-full h-full bg-white rounded-2xl shadow-md overflow-hidden">
                <NoDataFound />
            </div>
        </div>
    );
}