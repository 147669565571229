export const MOVE_UP_MAINNET_URL = "http://localhost:3000";
// export const MOVE_UP_MAINNET_URL = "https://moveuprpc.clvscan.com";
export const MOVE_UP_NODE_URL = process.env.NODE_URL || MOVE_UP_MAINNET_URL;
export const MOVE_UP_FAUCET_URL = process.env.FAUCET_URL || MOVE_UP_MAINNET_URL;

export const NetworkToNodeAPI: Record<string, string> = {
    mainnet: `${window.location.origin}/v1`,
    testnet: `${window.location.origin}/v1`,
    devnet: `${window.location.origin}/v1`,
};

export enum Network {
    MAINNET = "mainnet",
    TESTNET = "testnet",
    DEVNET = "devnet",
}

export const moveupCoinType: string = '0x1::eth::ETH';

export const LOCAL_STORAGE_KEY_ACCOUNT = 'account-info';
export const LOCAL_STORAGE_KEY_WALLET = 'wallet-info';