import { CircularProgress, Tooltip } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { PaginationRounded } from "../component/PaginationRounded";
import { apiService } from "../utils/httpHelper";
import { shortAddress } from "../utils/StringHelper";
import BigNumber from "bignumber.js";
import { MOVEUP_DECIMALS } from "../utils/TransactionsUtils";
import { timeFromNow } from "../utils/TimeHelper";
import { Link } from "react-router-dom";
import { NoDataFound } from "../component/NoDataFound";

type LayerTransactionsParams = {
    title: string,
    // 0 -> deposit  (1 -> 2)
    // 1 -> withdraw (2 -> 1)
    kind: number,
}

type RequestResult = {
    total: number,
    data: LayerTransactionModel[],
}

const pageCount = 10;
export function LayerTransactions(params: LayerTransactionsParams) {
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentTotalPage, setCurrentTotalPage] = useState<number>(1);
    const [LayerTransactions, setLayerTransactions] = useState<LayerTransactionModel[]>([]);

    const requestTx = useCallback(async () => {
        try {
            const url = `https://api-messenger.moveup.dev/list?kind=${params.kind}&limit=10&skip=${(currentPage - 1) * pageCount}`;
            setShowLoading(true);
            const { data, total } = await apiService.get<RequestResult>(url);
            if (currentPage) {
                setCurrentTotalPage(Math.ceil(total / pageCount));
            }
            setLayerTransactions(data);
            console.log('requestLayerTransactions data: ', data)
            console.log('requestLayerTransactions total: ', total)
        } catch (e) {
            console.error('fetchLayerTransactions error', e);
        } finally {
            setShowLoading(false);
        }
    }, [params.kind, currentPage]);

    useEffect(() => {
        requestTx();
    }, [requestTx])

    return (
        <div className='w-full h-full flex flex-col items-center min-h-screen'>
            <div className='w-full max-w-7xl h-full overflow-y-auto flex flex-col'>
                <div className='mt-16 mb-8 text-left text-4xl font-extrabold'>{params.title}</div>
                <div className='w-full bg-white rounded-2xl shadow-md overflow-hidden md:overflow-x-auto'>
                    <div className='h-16 flex flex-row items-center px-5'>
                        <div className='font-semibold flex-2 text-gray-600'>L2 HASH</div>
                        <div className='font-semibold flex-2 text-gray-600'>TIMESTAMP</div>
                        <div className='font-semibold flex-2 text-gray-600'>SENDER</div>
                        <div className='font-semibold flex-2 text-gray-600'>SEND TO</div>
                        <div className='font-semibold flex-1 text-gray-600 text-right'>AMOUNT</div>
                    </div>
                    <div>
                        {
                            !showLoading && LayerTransactions.map((tx, index) => {
                                const amount = new BigNumber(tx.amount).shiftedBy(-MOVEUP_DECIMALS).toString(10);
                                return (
                                    <div key={`layer-tx-page-${index}-${tx.id}`} className="flex flex-col">
                                        <div className='w-full h-px bg-gray-200 md:w-149' />
                                        <Link to={`/tx/${tx.l2_tx_hash}`} className="flex h-16 px-5 items-center hover:bg-gray-100 cursor-pointer">
                                            <div className='flex-2 font-semibold text-indigo-900 hover:text-indigo-600'>
                                                <Tooltip title={tx.l2_tx_hash} placement="top">
                                                    <Link to={`/tx/${tx.l2_tx_hash}`}>{shortAddress(tx.l2_tx_hash)}</Link>
                                                </Tooltip>
                                            </div>
                                            <div className='flex-2 font-semibold'>{timeFromNow(new Date(tx.created_at))}</div>
                                            <div className='flex-2 font-semibold text-indigo-900 hover:text-indigo-600'>
                                                <Tooltip title={tx.from} placement="top">
                                                    <Link to={`/address/${tx.from}/transactions`}>{shortAddress(tx.from)}</Link>
                                                </Tooltip>
                                            </div>
                                            <div className='flex-2 font-semibold text-indigo-900 hover:text-indigo-600'>
                                                <Tooltip title={tx.to} placement="top">
                                                    <Link to={`/address/${tx.to}/transactions`}>{shortAddress(tx.to)}</Link>
                                                </Tooltip>
                                            </div>
                                            <div className='flex-1 font-semibold text-right'>{`${amount} MEP`}</div>
                                        </Link>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {showLoading && <div className='my-28 flex justify-center items-center'><CircularProgress /></div>}
                    {!showLoading && currentPage === 1 && LayerTransactions.length === 0 && <NoDataFound />}
                </div>
                <div className="h-7" />
                {currentTotalPage > 1 && <PaginationRounded totalCount={currentTotalPage} onChange={setCurrentPage} />}
            </div>
        </div>
    );
}