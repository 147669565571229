/**
 * Omit the middle part of the displayed address
 *
 * @param {string}   - example: 0x8B4de256180CFEC54c436A470AF50F9EE2813dbB
 * @returns {string} - example: 0x8B4de...E2813dbB
 */
export const shortAddress = (address?: string): string => {
    if (!address) return '';
    if (address.length < 16) return address;
    if (address.startsWith('0x000000000000000000000000')) {
        address = convertMoveUpAddressToEthMode(address);
    }
    return address.substring(0, 8) + '...' + address.substring(address.length - 8, address.length);
}

export const convertMoveUpAddressToEthMode = (address: string): string => {
    if (!address) return '';
    const prefixDiff = '0x000000000000000000000000';
    if (address.startsWith(prefixDiff) && address.length === 66) {
        return address.replace(prefixDiff, '0x');
    }
    if (address === '0x1') {
        return address;
    }
    if (address.startsWith('0x') && address.length < 42) {
        const lengthDiff = 42 - address.length;
        let lengthStr = '';
        for (let index = 0; index < lengthDiff; index++) {
            lengthStr = `${lengthStr}0`
        }
        return address.replace('0x', `0x${lengthStr}`).toLowerCase()
    }
    return address
}

export const capitalizeFirstLetter = (str: string): string => {
    if (!str) return '';
    if (str.length <= 1) return str.toUpperCase();
    return str.charAt(0).toUpperCase() + str.slice(1);
}