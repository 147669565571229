import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.scss';
import { AppContextProvider } from "./context/AppContext";
import { SnackbarProvider } from './context/SnackbarContext';
import { AddressInfoPage } from './page/AddressInfoPage';
import { AnalyticsPage } from './page/AnalyticsPage';
import { BlockInfoPage } from './page/BlockInfoPage';
import { BlockchainPage } from './page/BlockchainPage';
import { BlocksPage } from './page/BlocksPage';
import Homepage from './page/Home/HomePage';
import IndexPage from './page/IndexPage/IndexPage';
import { TokenInfoPage } from './page/TokenInfoPage';
import { Empty404Page } from './page/Empty404Page';
import { TransactionsPage } from './page/TransactionsPage';
import { TransactionInfoPage } from './page/userTransactions/TransactionInfoPage';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SupportedAssetsList } from './page/SupportedAssetsList';
import { CoinsPage } from './page/CoinsPage';
import { LayerTransactions } from './page/LayerTransactions';

function App() {
  const queryClient = new QueryClient();

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Homepage />,
      children: [
        { index: true, element: <IndexPage /> },
        { path: "txs", element: <TransactionsPage /> },
        { path: "analytics", element: <AnalyticsPage /> },
        { path: "blockchain", element: <BlockchainPage /> },
        { path: "blocks", element: <BlocksPage /> },
        { path: "tx/:txId", element: <TransactionInfoPage /> },
        {
          path: "address/:addressId/*",
          element: <AddressInfoPage />,
          children: [
            { path: "code/*" },
          ]
        },
        { path: "block/:blockId/*", element: <BlockInfoPage /> },
        { path: "token/:tokenId", element: <TokenInfoPage /> },
        { path: "tx-l1-2", element: <LayerTransactions kind={0} title='Latest L1→L2 Transactions' /> },
        { path: "tx-l2-1", element: <LayerTransactions kind={1} title='Latest L2→L1 Transactions' /> },
        { path: "l1/assets", element: <SupportedAssetsList /> },
        { path: "coins", element: <CoinsPage /> },
        { path: "*", element: <Empty404Page /> },
      ],
    },
  ]);

  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider>
        <AppContextProvider>
          <RouterProvider router={router} />
        </AppContextProvider>
      </SnackbarProvider>
    </QueryClientProvider>

  );
}

export default App;
