import { isMobile } from "react-device-detect";
import { useSnackbar } from "../context/SnackbarContext";

export function Footer() {
    const showSnackbar = useSnackbar();

    function gotoTestnet() {
        showSnackbar('Coming soon');
    }

    function gotoWebsite() {
        showSnackbar('Coming soon');
    }

    function gotoDocumentation() {
        showSnackbar('Coming soon');
    }

    if (isMobile) {
        return (
            <div className="w-full flex flex-col pl-4">
                <div className='text-gray-700 text-base font-bold'>Infrastructure</div>
                <div className='h-4' />
                <div className='text-gray-600 text-sm font-semibold cursor-pointer' onClick={gotoTestnet}>
                    Testnet Faucet
                </div>
                <div className='h-8' />
                <div className='text-gray-700 text-base font-bold'>About</div>
                <div className='h-4' />
                <div className='text-gray-600 text-sm font-semibold cursor-pointer' onClick={gotoWebsite}>
                    Website
                </div>
                <div className='text-gray-600 text-sm font-semibold cursor-pointer' onClick={gotoDocumentation}>
                    Documentation
                </div>
                <div className='h-6' />
                <img src='/images/logo.svg' width={75} alt="" />
                <div className='flex flex-col'>
                    <div className='text-gray-600 text-sm font-semibold'>© 2023 AnyVM Labs</div>
                    <div className='text-gray-600 text-sm'>AnyVM Explorer is a tool for inspecting and</div>
                    <div className='text-gray-600 text-sm'>analyzing AnyVM blockchains. </div>
                </div>
                <div className="h-10" />
            </div>
        );
    }

    return (
        <div className='w-full max-w-7xl flex flex-col items-center m-auto z-10 relative'>
            <div className='w-full h-px bg-gray-200' />
            <div className='w-full flex flex-row pt-7 pb-24 pr-10'>
                <img src='/images/logo.svg' width={75} alt="" />
                <div className='w-7' />
                <div className='flex flex-col'>
                    <div className='text-gray-600 text-sm font-semibold'>© 2023 AnyVM Labs</div>
                    <div className='text-gray-600 text-sm'>AnyVM Explorer is a tool for inspecting and</div>
                    <div className='text-gray-600 text-sm'>analyzing AnyVM blockchains. </div>
                </div>
                <div className='flex-1' />
                <div className='flex flex-col'>
                    <div className='text-gray-700 text-base font-bold'>Infrastructure</div>
                    <div className='h-4' />
                    <div className='text-gray-600 text-sm font-semibold cursor-pointer' onClick={gotoTestnet}>
                        Testnet Faucet
                    </div>
                </div>
                <div className='w-48' />
                <div className='flex flex-col'>
                    <div className='text-gray-700 text-base font-bold'>About</div>
                    <div className='h-4' />
                    <div className='text-gray-600 text-sm font-semibold cursor-pointer' onClick={gotoWebsite}>
                        Website
                    </div>
                    <div className='text-gray-600 text-sm font-semibold cursor-pointer' onClick={gotoDocumentation}>
                        Documentation
                    </div>
                </div>
            </div>
        </div>
    );
}