import { Snackbar } from '@mui/material';
import { ReactNode, createContext, useContext, useState } from 'react';

const SnackbarContext = createContext((message: string) => { });

export const useSnackbar = () => useContext(SnackbarContext);
export function SnackbarProvider({ children }: { children: ReactNode }) {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');

    const showSnackbar = (message: string) => {
        setMessage(message);
        setOpen(true);
    };

    const closeSnackbar = () => {
        setOpen(false);
    };

    return (
        <SnackbarContext.Provider value={showSnackbar}>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={open}
                message={message}
                autoHideDuration={3000}
                onClose={closeSnackbar}
            />
            {children}
        </SnackbarContext.Provider>
    );
}
