import { Tab, Tabs, styled } from "@mui/material";

export const AppTabs = styled(Tabs)({
    minHeight: 'unset',
    '& .MuiTabs-indicator': {
        backgroundColor: '#47409C',
    },
});

export const AppTab = styled(Tab)({
    padding: 0,
    fontWeight: 600,
    marginRight: 32,
    minWidth: 'unset',
    alignItems: 'start',
    color: '#80899A',
    textTransform: 'none',
    '&.Mui-selected': {
        color: '#212529',
    },
});

export interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}