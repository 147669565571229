import { AbiItem } from 'web3-utils';

export const libAddressManagerABI: AbiItem[] = [
    {
        "inputs": [],
        "name": "getAllSupportL1TokenAddr",
        "outputs": [
            {
                "internalType": "address[]",
                "name": "",
                "type": "address[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "l1Address",
                "type": "address"
            }
        ],
        "name": "getSupportCoinType",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
];