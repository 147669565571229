import React, { useEffect, useState } from 'react';
import { BackButton } from '../../component/BackButton';
import { useParams } from 'react-router-dom';
import { Types } from '@anyvm/moveup-sdk';
import { useAppContext } from '../../context/AppContext';
import { AppTab, AppTabs, TabPanel } from '../../component/TabPanel';
import { CircularProgress } from '@mui/material';
import { useSnackbar } from '../../context/SnackbarContext';
import { TxOverviewTab } from './tabs/TxOverviewTab';
import { TxBalanceChangeTab } from './tabs/TxBalanceChangeTab';
import { TxEventsTab } from './tabs/TxEventsTab';
import { TxPayloadTab } from './tabs/TxPayloadTab';
import { TxChangesTab } from './tabs/TxChangesTab';
import { CopyIcon } from '../../component/CopyIcon';

export function TransactionInfoPage() {
    const params = useParams();
    const appContext = useAppContext();
    const [txData, setTxData] = useState<Types.Transaction | undefined>(undefined)
    const [blockData, setBlockData] = useState<Types.Block | undefined>(undefined)
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [tabValue, setTabValue] = useState<number>(0);
    const showSnackbar = useSnackbar();

    const loadTxDetail = async () => {
        const id = params.txId
        if (id) {
            if (id.startsWith('0x')) {
                const txInfo = await appContext.provider.getTransactionByHash(id);
                const blockInfo = await appContext.provider.getBlockByVersion(Number((txInfo as Types.Transaction_UserTransaction).version));
                setTxData(txInfo)
                setBlockData(blockInfo)
                console.log('Types.Transaction A :', txInfo, blockInfo)
            } else {
                const txInfo = await appContext.provider.getTransactionByVersion(Number(id));
                const blockInfo = await appContext.provider.getBlockByVersion(Number(id));
                setTxData(txInfo)
                setBlockData(blockInfo)
                console.log('Types.Transaction B :', txInfo, blockInfo)
            }
        }
    }

    useEffect(() => {
        loadTxDetail()
    }, [])

    return (
        <div>
            <div className='mt-18 md:mt-5' />
            <BackButton />
            <div className='mt-8 md:mt-0 h-auto w-full flex justify-start text-h1'>Transaction</div>
            {(!showLoading && txData) ?
                <>
                    <div className='mt-2 md:mt-0 h-auto w-full whitespace-pre-wrap overflow-hidden break-all text-sm flex-wrap'>
                        <span className=''>{txData?.hash}</span>
                        <div className='inline-block'><CopyIcon text={txData?.hash} /></div>
                    </div>
                    <div className="w-full h-full mt-8 md:mt-4 bg-white rounded-2xl overflow-hidden shadow-md">
                        <div className="px-5">
                            <AppTabs value={tabValue} className='' variant='scrollable' onChange={(_, newValue) => setTabValue(newValue)}>
                                <AppTab label="Overview" />
                                {txData.type === 'user_transaction' && <AppTab label="Balance Change" />}
                                {txData.type === 'user_transaction' && <AppTab label="Events" />}
                                {txData.type === 'user_transaction' && <AppTab label="Payload" />}
                                {txData.type === 'user_transaction' && <AppTab label="Changes" />}
                            </AppTabs>
                            <TabPanel value={tabValue} index={0}><TxOverviewTab txData={txData} blockData={blockData} /></TabPanel>
                            <TabPanel value={tabValue} index={1}><TxBalanceChangeTab transaction={txData} /></TabPanel>
                            <TabPanel value={tabValue} index={2}><TxEventsTab transaction={txData} /></TabPanel>
                            <TabPanel value={tabValue} index={3}><TxPayloadTab transaction={txData} /></TabPanel>
                            <TabPanel value={tabValue} index={4}><TxChangesTab transaction={txData} /></TabPanel>
                        </div>
                    </div>
                </>
                :
                <div className="my-28 flex justify-center items-center"><CircularProgress /></div>}
        </div>
    );
}
